import React, { PureComponent } from 'react';
import get from 'lodash/get';
import has from 'lodash/has';

const temporaryLanguageStrings = {};

export function registerLanguageString(id, value){
  temporaryLanguageStrings[id] = value;
}

window.getRegisteredLanguageStrings = function(){
  return temporaryLanguageStrings;
};

window.getMissingLanguageStrings = function(){
  const missingStrings = {};
  for(let n in temporaryLanguageStrings){
    if(temporaryLanguageStrings.hasOwnProperty(n)){
      if(!has(window.ls, n)){
        missingStrings[n] = getLanguageString(n);
      }
    }
  }
  return missingStrings;
};

window.getChangedLanguageStrings = function(){
  const missingStrings = {};
  for(let str in temporaryLanguageStrings){
    var reg = temporaryLanguageStrings[str];
    var lstr = window.ls[str];
    if(reg != lstr){
      missingStrings[str] = reg;
    }

  }

  return missingStrings;
};

const escape = (input) => {
  return input.replace(/&#(\d{0,5});/g, (match, code) => {
    // Convert the matched character code to a number
    code = Number(code);
    // Ensure that the code is within the valid range
    if(code > 0 && code <= 65535){
      // And if it is then return it's string representation
      return String.fromCharCode(code);
    }
    // Or otherwise replace with nothing (e.g: retain)
    return match;
  });
};

export function getLanguageString(id){
  return escape(get(window.ls, id, get(window.ls, id, temporaryLanguageStrings[id] || `<ls.${id}>`)));
}

export class LanguageString extends PureComponent {
  render(){
    const { id } = this.props;
    return getLanguageString(id);
  }
}

export class LanguageBlock extends PureComponent {
  render(){
    const { id, link } = this.props;
    const parseDisplayBlock = /(?:\<([^\s]+?).*?\>)?(.+?)(?:\<\/.*?\>|(?=\<)|$)/g;
    const displayBlock = getLanguageString(id);
    // Prepare the resulting display
    const display = [];
    // Process each section of the provided block
    let current = null;
    let key = 0;
    let linkFound = !link;
    while((current = parseDisplayBlock.exec(displayBlock)) !== null){
      // And add it the compiled display
      if(current[1]){
        switch(current[1]){
          case 'a':
            linkFound = true;
            display.push(<a {...link} key={key++}>{current[2]}</a>)
            break;
          default:
            display.push(current[2]);
        }
      }
      else {
        display.push(current[2]);
      }
    }

    // Return the processed displayed
    if(linkFound){
      return display;
    }
    else {
      return (
        <a {...link}>{display}</a>
      )
    }
  }
}

// EXPORT CONSTS
export * from '../languages/constants';
export default LanguageString;
