import './globalnav.scss';

//console.log('WR.JS')

// BROWSER DETECTION

navigator.browser = (function(){
  var ua= navigator.userAgent, tem,
  M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if(/trident/i.test(M[1])){
      tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE '+(tem[1] || '');
  }
  if(M[1]=== 'Chrome'){
      tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
      if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
  if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
  return M.join(' ');
})();


// PUT OUT MESSAGE IF UNSUPPORTED BROWSER IS BEING USED

/* Adds Element BEFORE NeighborElement */
Element.prototype.appendBefore = function(element) {
  element.parentNode.insertBefore(this, element);
}, false;

/* Adds Element AFTER NeighborElement */
Element.prototype.appendAfter = function(element) {
  element.parentNode.insertBefore(this, element.nextSibling);
}, false;

var _handleUnsupportedBrowser = function() {

  var str = navigator.browser;
  var browser = str.substring(0, str.indexOf(" "));
  var version = str.substring(str.indexOf(" "));
  version = version.trim();
  version = parseInt(version);

	var unsupported = browser == 'MSIE' && version < 11;

	//unsupported = true;

	/*
	console.log(browser);
	console.log(version);
	console.log(unsupported);
	*/

  if(unsupported) {

		//console.log('UNSUPPORTED')

		// GET THE CURRENT ENVIRONMENTS
		var _environment = typeof psGoSite == 'undefined' ? '' : psGoSite;
		var _environmentDetail = typeof psGoSite == 'undefined' || (psGoSite && psGoSite == '') ? '' : '5';
		// BUILD THE CORRECT P4INBOUND LINK DEPENDING ON ENVIRONMENT
		var _p4inbound =  'https://' + _environmentDetail + 'detail.webrootanywhere.com/p4inbound.asp?CMD=';

		var NewElement = document.createElement('p');
		NewElement.innerHTML = languageStrings.text_unsupportedbrowser + ' ' + languageStrings.text_unsupportedbrowser_upgrade + ' <a href="' + _p4inbound + 'SUPPORTEDBROWSERS" target="_blank">' + window.ls.text_more_info + '</a>';
		NewElement.id = 'unsupportedbrowser';
		NewElement.className = 'error';

		NewElement.appendBefore(document.getElementById('contentarea'));

	};

};

_handleUnsupportedBrowser();

var wr = (function($){

// ***************************************************************************************************************
// PRIVATE VARIABLES
// ***************************************************************************************************************

	// GET THE CURRENT ENVIRONMENTS
	var _environment = typeof psGoSite == 'undefined' ? '' : psGoSite;

	var _environmentDetail = typeof psGoSite == 'undefined' || (psGoSite && psGoSite == '') ? '' : '5';

	// BUILD THE CORRECT P4INBOUND LINK DEPENDING ON ENVIRONMENT
	var _p4inbound =  'https://' + _environmentDetail + 'detail.webrootanywhere.com/p4inbound.asp?CMD=';

	var _html = {
		header: '',
		navigation: '',
		menu: '',
		help: '',
		footer: ''
	};

// ***************************************************************************************************************
// INIT & SETUP
// ***************************************************************************************************************

	var _init = function(data){

		var gsm = window.location.pathname.indexOf('gsm.aspx') > -1;



			_appendBodyClass();

			_createListeners();


		if(!gsm) {


			_createMenu();

			_createHelp();

			_createHeader();

			_createNavBar();

			_createFooter();

			_buildWrapper();

			//_checkRename();

		}

	};

	var _createListeners = function() {

		var $window = $(window);
		var $body = $('body');

		// HANDLE ALL DOCUMENT CLICKS
		$(document).on('click',function(e) {
      _handleDocumentClick(e);
		});

		$body.on('click','.nav-toggle',function(){

			// NAVIGATION TOGGLE
			if($window.width() >= 768) {
				$body.toggleClass('nav-is-closed-md');
			} else {
				$body.add('html').toggleClass('nav-is-open-xs').removeClass('show-consoles');
			}

			_handleWRJSGridResize();

		});



		$body.on('keyup', '#wrjs_modal_renameconsole input#consolename', function(e) {

			// DISABLED ENTERING SAME NAME AS ALREADY EXISTS
			var t = $(this);
			if(t.val() == psMarName) {
			  $('#wrjs_modal_renameconsole .confirmclick').addClass('disabled');
			} else {
			  $('#wrjs_modal_renameconsole .confirmclick').removeClass('disabled');
			}

		}).on('click', '.renameconsole', function () {

			// RENAME CONSOLE / CHANGE MARNAME BUTTON
			_renameConsole(this);

		})

		// .on('click','#header-console-select a',function(e){
		//
		// 	// SHOW/HIDE THE DIFFERENT TYPES OF CONSOLES IN THE CHANGE CONSOLE DROPDOWN MENU
		// 	e.stopPropagation();
		//
		// 	var addCls = $(this).attr('class');
		//
		// 	var removeCls = addCls === 'show-gsm-consoles' ? 'show-ep-consoles' : 'show-gsm-consoles';
		//
		// 	$('#changeconsole').removeClass(removeCls).addClass(addCls);
		//
		// })
		//
		.on('click','.changeconsole:not(.header-console-change)',function(e){

			e.stopPropagation();

			$body.toggleClass('show-consoles');

		})
		.on('click','.console',function(){

			// CONSOLES TAKE SOMETIME TO LOAD SO ON CLICK OF A CONSOLE NAME, DISPLAY LOADING MASK TO INIDICATE TO THE USER SOMETHING IS HAPPENING
			$body.removeClass('show-consoles').mask(window.ls.text_loading, 'white');

		});

		$window.resize(function(){

			var isResizingClass = 'is-resizing';

			// ADD RESIZING CLASS TO BODY ON WINDOW RESIZE
			$body.addClass(isResizingClass);

			setTimeout(function(){
				//REMOVE CLASS AFTER WINDOW HAS BEEN RESIZED
				$body.removeClass(isResizingClass);
			}, 250);


			if(_html.navigation.length) {
				_createMenuOverflow();
			};

		});

	};


// ***************************************************************************************************************
// PRIVATE FUNCTIONS
// ***************************************************************************************************************


	var _handleDocumentClick = function(e) {

		// HEADER DROPDOWN LOGIC
		var target = $(e.target);

		var dropdownCls = 'has-dropdown';
		var clickedcls = 'clicked';

		if(target.add(target.parents()).hasClass(dropdownCls)){
			if(target.closest('.' + dropdownCls).hasClass(clickedcls)){
				target.closest('.' + dropdownCls).removeClass(clickedcls);
			} else {
				$('.'+clickedcls).removeClass(clickedcls);
				target.closest('.' + dropdownCls).addClass(clickedcls);
			}
		} else {
			$('.'+clickedcls).removeClass(clickedcls);
		};

	};

	var _checkRename = function() {

		// TEST WHETEHR TO CHECK FOR UNNAMED CONSOLE BEING PRESENT AND AUTO MODAL
		var bodyid = $('body')[0].id;
		var checkrename = bodyid == 'page_home';
		var access = typeof psUCCALevel != 'undefined' ? psUCCALevel : 0;

		if(checkrename && access > 127) {
			var unnamed = psMarName == window.ls.text_unnamedconsole || psMarName.length == 0;
			if(unnamed) {
				_renameConsole();
			};
		};

	};


	var _isBusiness = function() {

		// CHECK WHETHER CURRENT CONSOLE IS CONSUMER OR BUSINESS/GSM
		// CHECK THE SUPPORT LINK, AND WHETHER THIS GOES TO P4INBOUND (Consumer) OR MYSUPPORT (Business/GSM)

		var business = false;

		var nav = $.WebrootUI && $.WebrootUI.navigation;
		if(nav) {
			$.each(nav, function(i, row) {
				if(row.cls == 'nav-support') {
					if(row.link.toLowerCase().indexOf('p4inbound') == -1) {
						business = true;
					};
					return false;
				};
			})
		};

		return business;

	};

	var _returnSubDomain = function() {

		var url = window.location.host;
		var parts = url.split('.');
		var subdomain = parts[0];

		// CHECK FOR FIRST DIGIT BEING A NUMBER
		var env = subdomain.split('');

		// IF FIRST CHARACTER IS A NUMBER
		if(!isNaN(env[0])) {
			// REMOVE FIRST CHARACTER FROM ARRAY, THEN REJOIN
			env.shift();
			subdomain = env.join('');
		};

		return subdomain;

	};


	var _returnHelpLink = function() {

		// RETURN THE CORRECT HELP LINK REGARDLESS OF CONSOLE LOGGED INTO
		// CHECK THE SUBDOMAIN
		// CHECK CONSOLE IS CONSUMER OR BUSINESS
		// RETURN CORRECT LINK

		var cmd = 'UBERHELP'; // SET DEFAULT VALUE TO UBER HELP

		var subdomain = _returnSubDomain();
		var business = _isBusiness();

		switch(subdomain) {

			case 'my':

				// MOBILE BUSINESS SHARES THE MY SUBDOMAIN, SO CHECK THE ACTUAL HOST STRING
				if(location.host.indexOf('webrootmobile') > -1) {

					cmd = 'UBERMOBILESMEHELP';

				} else {

					// IF NOT CONSUMER CONSOLE, DEFAULT HELP LINK TO SME HELP (Business)
					// IF PAGE IS "gsm.aspx", SET TO MSP HELP (GSM)
					if(business) {
						cmd = 'UBERSMEHELP';
						if(window.location.pathname.toLowerCase() == '/gsm.aspx') {
							cmd = 'UBERMSPHELP';
						};
					};

				};

				break;

			case 'mypcsecurity':
				cmd = business ? 'UBERSMEHELP' : 'UBERPCHELP';
				break;

			case 'mydata':
				cmd = 'UBERBACKUPSHELP';
				break;

			case 'mypass':
				cmd = 'UBERPASSWORDSHELP';
				break;

			case 'mobile':
				cmd = 'UBERMOBILEHELP';
				break;

			default:
				// DO NOTHING - DEFAULT TO UBERHELP

		};

		return _p4inbound + cmd;

	};


	var _handleWRJSGridResize = function() {

		// THIS FUNCTION CHECKS IF ANY STICKY / FIXED WRJS HEADERS EXIST
		// IF SO, HIDE THEM, RESIZE, THEN RESHOW
		var sticky = $('.wrjs_table.sticky');
		if(sticky.length > 0) {
			sticky.hide();
			setTimeout(function() {
				sticky.show();
				$(window).resize();
			},800)
		};

	};


	var _appendBodyClass = function() {

		var cls = [];

		var host = location.host;

		var subdomain = _returnSubDomain();

		if(location.pathname.indexOf('endpointprotection') > -1 || host.indexOf('mypass') > -1) {
			cls.push('mobile-unsupported');
		};

		if(subdomain.indexOf('mysupport') > -1) {
			cls.push('is-support');
		};

		if(navigator && navigator.userAgent && navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
			cls.push('browser-is-firefox');
		};

		$('body').addClass(cls.join(' '));

	};

	var _buildWrapper = function() {

		//console.log('_buildWrapper');

		var body = $('body');

		//body.prepend(_html.header);

		if(_html.navigation.length) {
			body.addClass('nav-is-visible');
		};

		body.prepend(_html.header + _html.navigation).append(_html.footer);

		_applySelectedNav();

		if(_html.navigation.length) {
			setTimeout(function() {
				_createMenuOverflow();
			},500)
		}



	};


	var _applySelectedNav = function(href) {

		var current = location.origin + location.pathname;
		var navwrapper = $('#navwrapper')
		var navitems = $('li a', navwrapper);
		var subdomain = _returnSubDomain();

		$.each(navitems, function(i, item) {

			var match = false;
			var a = $(item);
			var li = a.closest('li');

			// IF BROWSER LOCATION MATCHES ONE OF THE NAV HREF VALUES
			var href = item.href;
			if(current == href) {
				match = true;
			};

			// BACKUP AND SYNC
			if(subdomain.indexOf('mydata') > -1 && current.toLowerCase().indexOf('sync') > -1) {
				match = true;
			};

			// LASTPASS REQUIRES SPECIFIC MATCHING
			if(subdomain.indexOf('mypass') > -1 && href.indexOf('PC=PASS') > -1) {
				match = true;
			};

			// CONSUMER REQUIRES SPECIFIC MATCHING
			if(href.indexOf('mypcsecurity') > -1 && current.indexOf('mypcsecurity') > -1) {
				match = true;
			};

			// ENDPOINT PROTECTION REQUIRES SPECIFIC MATCHING
			if(href.indexOf('subportal') > -1 && current.indexOf('endpointprotection') > -1) {
				match = true;
			};

			// PC SECURITY REQUIRES SPECIFIC MATCHING
			if(href.indexOf('subportal') > -1 && current.indexOf('consumer') > -1) {
				match = true;
			};

			if(match) {
				li.addClass('nav-is-selected');
				return false;
			};

		});

		// CHECK ONE IS SELECTED
		if(subdomain != 'mysupport') {
			var selected = $('li.nav-is-selected', navwrapper);
			if(selected.length == 0 && $(window).width() >= 768) {
				$('li:eq(0)', navwrapper).addClass('nav-is-selected');
			};
		};

	};

	var _getConsoles = function(changeConsoleHref,changeConsoleTitleText){

		// SET THIS AS THE DEFAULT RESPONSE
		// PROVIDE A LINK BACK TO THE CONSOLE SELECTION SCREEN FOR THE USER IF AJAX CALL FAILS
		var consoleList = 	'<a href="'+ changeConsoleHref +'" class="header-console-change changeconsole" title="'+ changeConsoleTitleText +'">' +
													window.ls.text_change +
												'</a>';

		$.ajax({
			url: 'uberdetails.aspx?cmd=UB40SELLST',
			type: 'POST',
			data: {
				whome: psCookieLogon
			},
			success: function(data){

				data = JSON.parse(data);

				var isGSM = $('body').attr('id') === 'page_gsm';

				var changeConsoleCls = '';

				var lis = '';

				var gsmConsoles = data.msprecords;

				var uberConsoles = data.records;

				// IF MARID IS AVAILABLE REMOVE IT FROM EITHER CONSOLE OBJECT
				if(psMARID){
					gsmConsoles = $.grep(data.msprecords, function(e){
						return e.marid != psMARID;
					});

					uberConsoles = $.grep(data.records, function(e){
						return e.marid != psMARID;
					});
				}

				var _addHeader = function(object,headerText,headerCls){

					var header = {
						marname: headerText,
						martype: 'header',
						cls: headerCls
					};

					object.splice(0,0,header);
				};

				// IF GSM CONSOLES APPENED A SUBHEADER TO THE OBJECT
				if (gsmConsoles && gsmConsoles.length > 0) {

					_addHeader(gsmConsoles,window.ls.text_gsmconsoles,'is-gsm');

				}

				// IF UBER CONSOLES APPENED A SUBHEADER TO THE OBJECT
				if (uberConsoles && uberConsoles.length > 0) {

					_addHeader(uberConsoles,window.ls.text_businessconsumerconsoles,'is-uber');

				}

				$('.header-console').addClass('has-consoles');

				// COMBINE BOTH CONSOLES TYPES
				var consoles = $.merge(gsmConsoles,uberConsoles);

				if (consoles && consoles.length > 0) {

					for (let i = 0; i < consoles.length; i++) {

						var item = consoles[i];

						var cls = '';

						var li = '';

						if (item.martype === 'header') {

							li = 	'<li class="header-console-group-header ' + item.cls + '" title="' + item.marname + '">' +
											item.marname +
										'</li>';

						}else{

							item.cls = item.martype === 'D' ? 'is-gsm' : 'is-uber';

							li = 	'<li class="' + item.cls + '">' +
											'<a href="selection.aspx?S=Y&MAR=' + item.marid + '" class="console" title="'  + item.marname + '">' + item.marname + '</a>' +
										'</li>';
						}

						lis = lis + li;

					};

					consoleList = `<ul class="header-console-container">
													${lis}
												 </ul>`;

				}

				_returnConsoles(consoleList,changeConsoleCls);

			},
			error: function(){

				_returnConsoles(consoleList);

			}

		});

	};

	var _returnConsoles = function(consoles,consoleCls){

		// CONSOLE MENU ELEMENT
		let $changeConsole = $('#changeconsole');

		let subdomain = _returnSubDomain();

		// CHECK CONSOLE MENU ELEMENT EXISTS
		if($changeConsole.length > 0) {

			// PUSH HTML INTO THE CHANGE CONSOLE MENU ELEMENT AND REMOVE SPINNER
			$changeConsole.addClass(consoleCls).removeClass('is-loading').html(consoles);

			if (process.env.NODE_ENV === 'development' && subdomain !== "my") {
			  $('.header-console').addClass('has-consoles');
      }

		} else {

			// CONSOLE MENU ELEMENT DOES NOT EXIST YET SO RECALL FUNCTION AND CHECK AGAIN
			setTimeout(function(){
				_returnConsoles(consoles);
			},100);

		}

	};

	var _returnChangeConsole = function(mobile) {

		var html = '';

		if(typeof psMARID != 'undefined' && psMARID != '0' && $('body').attr('id') != "page_selection") {

			var marname = typeof psMarName != 'undefined' && psMarName.length > 0 ? psMarName : false;
			var unnamedconsole = typeof ls != 'undefined' && window.ls.text_unnamedconsole || 'Unnamed Console';
			var sitename = marname && marname != '?' ? marname : unnamedconsole;
			var sitenameHTML = '<span class="header-console-name" title="' + sitename + '">' + sitename + '</span>';

			var uberpermissions = typeof psUCCALevel != 'undefined' ? psUCCALevel == '128' : false;
			var gsmpermissions = typeof msp != 'undefined' ? msp.permissions.gsm == '128' : false;
			var pccesuritypermissions = typeof accessLevel != 'undefined' ? accessLevel == '128' : false;
			var permissions = uberpermissions || gsmpermissions || pccesuritypermissions;

			var rename = '';
			var renameTitleText = window.ls.text_renameconsole;
			var changeConsole = '';
			var changeConsoleTitleText = window.ls.text_changeconsole;
			var changeConsoleHref = typeof psURLPrefix != 'undefined' ? psURLPrefix + _environment + 'my.webrootanywhere.com/selection.aspx' : 'selection.aspx';

			var subdomain = _returnSubDomain();

			if(permissions && subdomain == "my") {

				if(mobile){

					rename = 	'<span class="renameconsole button icon icon_edit" title="' + renameTitleText + '"><b></b></span>';

				}else{

					rename = 	'<li class="visible-md">' +
											'<a href="#nogo" class="header-console-rename renameconsole" title="' + renameTitleText + '">' +
												window.ls.text_rename +
											'</a>' +
										'</li>';
				}

			}

			// ONLY PUT OUT CHANGE CONSOLE OPTION IF THE USER HAS MORE THAN ONE CONSOLE
			if(typeof psConsoleCount != 'undefined' && psConsoleCount > 1 && subdomain == "my" || process.env.NODE_ENV === 'development' && subdomain !== "my") {

				if(mobile){

					changeConsole = '<a href="#nogo" class="changeconsole button icon icon_refresh" title="'+ changeConsoleTitleText +'"><b></b></a>';

					html =	'<ul class="hidden-md">' +
										'<li class="global-nav-left-console">' +
											sitenameHTML +
											changeConsole +
											rename +
										'</li>' +
									'</ul>';

				}else{

					// GET CONSOLES FROM API AND PASS THROUGH ANY ENVIRONMENT OR TRANSLATED STRINGS THAT MAY BE REQUIRED
          // DELAY TO ALLOW HIGHER PRIORITY AJAX CALLS TO FIRE FIRST
					if (process.env.NODE_ENV === 'development' && subdomain !== "my") {

						_returnConsoles(`
							<ul class="header-console-container">
								<li class="is-gsm">
									<a class="console" href="/auth/switch/2/1">
											Main Access / MSP User <small>- Access Site 1 as GSM</small>
									</a>
								</li>
								<li class="is-gsm">
									<a class="console" href="/auth/switch/6/3">
										Business User With Data <small>- Access Site 3 as Business User</small>
									</a>
								</li>
								<li class="is-gsm">
									<a class="console" href="/auth/switch/6/4">
										Business User Without Data <small>- Access Site 4 as Business User</small>
									</a>
								</li>
								<li class="is-gsm">
									<a class="console" href="/auth/switch/5/1">
										Limited Access <small>- Access Site 1 as Limited GSM</small>
									</a>
								</li>
								<li class="is-gsm">
									<a class="console href="/auth/switch/3/2">
										Secondary Access <small>- Access Site 2 as GSM</small>
									</a>
								</li>
								<li class="is-gsm">
									<a class="console" href="/auth/switch/5/2">
										Limited Access <small>- Access Site 2 as Limited GSM</small>
									</a>
								</li>
								<li class="is-gsm">
									<a class="console" href="/auth/switch/1/1">
										All Access <small>- Access Site 1 as GSM (same user also has access to Site 2)</small>
									</a>
								</li>
								<li class="is-gsm">
									<a class="console" href="/auth/switch/1/2">
										All Access <small>- Access Site 2 as GSM (same user also has access to Site 1)</small>
									</a>
								</li>
							</ul>`);

					} else {

						setTimeout(function() {
							_getConsoles(changeConsoleHref,changeConsoleTitleText);
						},100);

          }

					// CREATE PARENT FOR THE CHANGE CONSOLE LINKS AND DISPLAY LOADING ANIMATION WHILE THE AJAX REQUEST RETURNS
					changeConsole = '<li id="changeconsole" class="header-console-change is-loading"><span>' + window.ls.text_loading + '</span></li>';

					html = 	'<span class="header-console has-dropdown">' +
										sitenameHTML +
										'<span class="fas fa-chevron-down"></span>' +
										'<ul class="header-dropdown">' +
											'<b class="arrow_up"><b></b></b>' +
											rename +
											changeConsole +
										'</ul>' +
									'</span>';

				}

			}else{

				if(permissions && subdomain == "my") {

					html = 	'<span class="header-console has-dropdown">' +
										sitenameHTML +
										'<span class="fas fa-chevron-down"></span>' +
										'<ul class="header-dropdown">' +
											'<b class="arrow_up"><b></b></b>' +
											rename +
										'</ul>' +
									'</span>';

				}else{

					html = 	'<span class="header-console">' +
										sitenameHTML +
									'</span>';

				}

				if(mobile){

					html = '<ul class="hidden-md">' +
										'<li class="global-nav-left-console">' +
											sitenameHTML +
											rename +
										'</li>' +
									'</ul>';

				}

			}

		}

		return html;

	};

	var _createHeader = function(){

		//console.log('_createHeaderrrr')

		var ce = '';
		if(typeof psVersionNumber != 'undefined') {
			ce = '<span id="ce">Management Console <em>CE ' + psVersionNumber + '</em></span>';
		}

		var str =   '<div class="global-nav-header">' +

									'<h1 class="global-nav-logo" title="Webroot">' +
										'<a href="' + location.protocol + '//' + _environment + 'my.webrootanywhere.com/home.aspx">Webroot</a>' +
									'</h1>' +

									ce +

									_returnChangeConsole() +

									'<span class="header-account" id="accountwrapper">' +

										_html.help +

										_html.menu +

									'</span>' +

									'<div class="nav-toggle">' +
										'<span class="line"></span>' +
										'<span class="line"></span>' +
										'<span class="line"></span>' +
									'</div>' +

								'</div>';

		_html.header = str;

	};

	var _createNavBar = function(){

		//console.log('_createNavBar')

		var $body = $('body');
		var help = $.WebrootUI.help;
    	var menu = $.WebrootUI.menu;

		var navigation = $body.is('#page_selection') || $body.is('#page_trust') ? [] : $.WebrootUI.navigation; // IF CONSOLE SELECTION OR SECURITY CODE SCREEN, REMOVE ALL THE GSM/EP NAVIGATION LINKS

		var subdomain = _returnSubDomain();
		if(typeof navigation == 'undefined') {

			// HANDLE SUPPORT WHEN NO MARID SELECTED (NAVIGATION DOESNT EXIST)
			if(subdomain.indexOf('mysupport') > -1) {

				navigation = [{
					text: 'Home',
					link: 'https://' + _environment + 'my.webrootanywhere.com/selection.aspx',
					cls: 'nav-home'
				}];

			} else if ($body.is('#page_selection') || $body.is('#page_trust')) {

				navigation = [];

			} else {

				// ALL OTHER PAGES WHERE NO NAV EXISTS
				// SET AS FALSE TO STOP NAVBAR BEING BUILT
				navigation = false;

			};

		};

		var user = $.WebrootUI.user;
    var footer = JSON.parse(JSON.stringify($.WebrootUI.footer));

 		// LOGGED IN SO DISPLAY MENU
		if (user && navigation) {


			if (help) {

				$.WebrootUI.help.splice(0,0,{
					header: $.WebrootUI && $.WebrootUI.help && $.WebrootUI.help[0] && $.WebrootUI.help[0].text || 'Help'
				});

				$.merge(navigation,help);

			}

			// GET ACCOUNT SETTINGS AND LOG OFF LINKS FROM MENU JSON
			if (menu) {

				navigation.push({
					header: user.email
				});

				if($body.is('#page_selection') && menu.length == 2){
					// REMOVE THE ACCOUNT SETTINGS LINK ON CONSOLE SELECTION SCREEN
					menu.shift();
				}

				$.merge(navigation,menu);

			}

			footer.splice(0,0,{
				header: 'Webroot'
			});

			$.merge(navigation,footer);

			var lis = '';
			var headers = false;

			for(let i = 0; i < navigation.length; i++) {

				var item = navigation[i];

				// DEFINE WHETHER TO ADD A TARGET VALUE TO THE AHREF
				var target = item.target ? ' target="' + item.target + '"' : '';

				// DEFINE CLASSES TO ADD TO THE LI (SELECTED / FIRST)
				var cls = [];
				item.active ? cls.push('nav-is-selected') : '';
				item.cls ? cls.push(item.cls) : '';
				var licls = cls.length > 0 ? ' class="' + cls.join(' ') + '"' : '';

				var li = '';

				// ADD ICON CLASS
				var iconCls = item.cls ? ' class="' + item.cls + '"' : '';

				if(item.header){

					headers = true;
					let headerCls = item.header === $.WebrootUI.user.email ? 'user' : item.header.toLowerCase();

					li = 	'<h2 title="' + item.header + '"' + licls + ' class ="nav-header nav-header-' + headerCls + ' hidden-md">' +
									item.header +
								'</h2>' +
								'<ul class="nav-group nav-group-' + headerCls + '">';

					if(i != 0) {
						li = '</ul>' + li;
					};


				} else {

					var txt = item.text;

					// IF DONOTTRANSLATE IS PASSED, WRAP IN MOTIONPOINT TAGS
					if(item.donottranslate) {
						txt = '<!--mp_trans_process_disable_start-->' + txt + '<!--mp_trans_process_disable_end-->';
					}

					// BUILD THE LI
					li = 	'<li' + licls + ' title="' +  item.text + '">' +
									'<a href="' + item.link + '" ' + target + '>' +
										//'<span' + iconCls + '></span>' +
										txt +
									'</a>' +
								'</li>';

				}

				lis += li;

			}

			var startul = lis.substring(1, 3) === 'li' ? '<ul id="nav-main">' : '';

			var str = 	//'<div class="global-nav-toggle nav-toggle">' +
							//'<span class="ion ion-chevron-left"></span>' +
						//'</div>' +
						'<div class="global-nav-left" id="navwrapper">' +
							'<div class="global-nav-left-container">' +
								_returnChangeConsole(true) +
								startul +
									lis +
								//'</ul>' +
							'</div>' +
						'</div>';

      _html.navigation = str;

		};

	};

	var _createMenuOverflow_old = function(){

		var $navWrapper = $('#navwrapper');

		var $navContainer = $navWrapper.children('.global-nav-left-container');

		var $dropdown = $('#overflow-dropdown');

		var navOverflow = '<div id="global-nav-left-overflow" class="global-nav-left-overflow has-dropdown visible-md">More <span class="fas fa-chevron-down/><ul id="overflow-dropdown" class="header-dropdown"><b class="arrow_up"><b></b></b></ul></div>';

		if($(window).width() >= 768){

			var offsetTop = $navContainer.offset().top;

			if($dropdown){
				$dropdown.find('*').not('b').remove();
			}

			$navContainer.children().find('li').each(function(i, a){

				if ($(a).offset().top > offsetTop) {

					// APPEND OVERFLOW DROPDOWN MENU
					if (!$navWrapper.find('#global-nav-left-overflow').length) {
						$navWrapper.append(navOverflow);
					}else{
						$('#global-nav-left-overflow').removeClass('hidden');
					}

					$('#overflow-dropdown').append($(a).not('.hidden-md').not('.hidden').not('.header-logout').not('.global-nav-left-console').clone());

				}

			});

			if(!$dropdown.children('li').length){
				$dropdown.parent().addClass('hidden');
			}

		}

	};

	var _returnOverflowHTML = function() {

		var navContainer = $('.global-nav-left-container')
		var lis = $('li[class^="nav-"]', navContainer);
		var lihtml = '';

		$.each(lis, function(i, li) {
			lihtml += li.outerHTML;
		});

		var html = '<div id="global-nav-left-overflow" class="global-nav-left-overflow has-dropdown visible-md">' +
									'More ' +
									'<span class="fas fa-chevron-down"></span>' +
									'<ul id="overflow-dropdown" class="header-dropdown">' +
										'<b class="arrow_up"><b></b></b>' +
										lihtml +
									'</ul>' +
								'</div>';

		return html

	};

	var _appendOverflow = function() {

		var exists = $('#global-nav-left-overflow').length > 0;
		if(!exists) {
			var html = _returnOverflowHTML();
			$('#navwrapper').append(html);
		};

	};

	var _overflowWidth = false;

	var _showHideOverflowItems = function() {

		// ALL MENU ITEMS NOW EXIST IN TEH MAIN NAV, AND IN THE OVERFLOW
		// THIS METHOD SHOW AND HIDES THE ITEMS DEPENDING ON WHETHER THEY FIT INTO THE MAIN NAV OR NOT

		var navContainer = $('.global-nav-left-container');
		var overflow = $('#global-nav-left-overflow');
		var navlis = $('li[class^="nav-"]', navContainer);
		var overflowlis = $('li', overflow);

		if(!_overflowWidth) {
			_overflowWidth = overflow.outerWidth();
		};

		// RESET SHOW HIDE SETTINGS
		navlis.removeClass('hidden');
		overflow.addClass('hidden');
		overflowlis.addClass('hidden');

		// GET THE WIDTH OF THE NAVIGATION BAR
		var navMainWidth = navContainer.outerWidth();
		var totalWidth = navMainWidth - _overflowWidth;

		// ONLY PERFORM IN DESKTOP MODE
		if($(window).width() >= 750){

			// ADD UP THE WIDTH OF THE LIS
			var liWidth = 0;

			$.each(navlis, function(i, li) {

				var $li = $(li);
				var newWidth = liWidth + $li.outerWidth();

				// COMPARE WIDTH OF LIS VERSUS THE TOTALWIDTH OF THE NAV BAR (MINUS OVERFLOW MENU)
				// IF ALL LIS DONT FIT, HIDE THE MAIN NAV, SHOW THE OVERFLOW
				if(newWidth > totalWidth) {

					// HIDE THE MAIN NAV LI
					$li.addClass('hidden');

					// SHOW THE OVERFLOW LI
					var cls = $li.attr('class').split(' ')[0];
					$('.' + cls, overflow).removeClass('hidden');
					overflow.removeClass('hidden');

				};

				liWidth = newWidth;

			})

		};

	};

	var _createMenuOverflow = function() {

		/*
		_appendOverflow();

		_showHideOverflowItems();
		*/

	};

	var _createMenu = function(){

		//console.log('_createMenu')

		var menu = $.WebrootUI.menu;

		if(menu && menu.length > 0) {

			var user = $.WebrootUI.user;

			if($('body').is('#page_trust') && menu.length == 2){
				// REMOVE THE ACCOUNT SETTINGS LINK ON TRUST SCREEN
				menu.shift();
			}

			if(user) {

				var lis = '';

				for(let i = 0; i < menu.length; i++) {
					var item = menu[i];
					var target = item.target ? ' target="' + item.target + '"' : '';
					var icon = item.cls ? '<span class="' + item.cls + '"></span>' : '';
					var cssCls = item.cls ? ' class="' + item.cls + '" ' : '';
					lis += '<li title="'+ item.text +'"><a href="' + item.link + '" ' + target + ' ' + cssCls + '>' + item.text + '</a></li>';
				};

				var str =   '<span id="account" class="has-dropdown header-account-user">' +
											'<!--mp_trans_disable_start--><span class="header-account-email" title="' + user.email + '">' + user.email + '</span><!--mp_trans_disable_end-->' +
											'<span class="fas fa-chevron-down"></span>' +
											'<ul class="header-dropdown">' +
												'<b class="arrow_up"><b></b></b>' +
												lis +
											'</ul>' +
										'</span>';

				_html.menu = str;

			};

		};

	};


	var _createHelp = function(){

		// console.log('_createHelp')

		var help = $.WebrootUI.help;
		var help_link = _returnHelpLink();
		var help_text = $.WebrootUI && $.WebrootUI.help && $.WebrootUI.help[0] && $.WebrootUI.help[0].text || 'Help';

		if(help && help.length > 0) {

			if($('body').is('#page_selection') || $('body').is('#page_trust')){
				help.pop();
			}

			var lis = '';

			for(let i = 0; i < help.length; i++) {
				var item = help[i];
				var target = item.target ? ' target="' + item.target + '"' : '';
				var icon = item.cls ? '<span class="' + item.cls + '"></span>' : '';
				var cssCls = item.cls ? ' class="' + item.cls + '" ' : '';
				lis += '<li title="'+ item.text +'"><a href="' + item.link + '" ' + target + ' ' + cssCls + '>' + item.text + '</a></li>';
			};

			var str =   '<span class="has-dropdown header-account-help">' +
										'<span class="fas fa-question"></span>' +
										'<ul class="header-dropdown">' +
											lis +
										'</ul>' +
									'</span>';

			_html.help = str;

		} else {
			_html.help = 	'<span class="header-account-help">' +
									 		'<a class="fas fa-question" href="' + help_link + '" target="_blank" title="' + help_text +'"></a>' +
										'</span>';
		}

	};


	var _createFooter = function(){

		var footer = $.WebrootUI.footer;

		if(footer && footer.length > 0) {

			var links = '';
			for(var i = 0, k = footer.length; i < k; i++){
				var item = footer[i];
				var target = item.target ? ' target="' + item.target + '"' : '';
				var l = item.link ? '<a href="' + item.link + '" ' + target + ' title="' + item.text + '">' + item.text + '</a>' : item.text;
				links += l;
			}
			var str = 	'<div class="global-nav-footer" id="footer">' +
										'<p>' + links + '</p>' +
									'</div>';

			_html.footer = str;

		}

	};


	// THIS IS CALLED EITHER ON ENTRY WHEN CONSOLE NANE EQUALS "UNNAMED CONSOLE"
  // OR IF USER CLICKS "RENAME CONSOLE" BUTTON
  var _renameConsole = function(el) {

		// DONT SHOW RENAME CONSOLE POPUP IF TERMS NEEDS TO BE ACCEPTED
		var termsPresent = _termsPresentOnGSM();
		if(!termsPresent) {

			var sitename = psMarName == '?' ? '' : psMarName;

      var name = el ? sitename : '';

      var h1 = window.ls.text_renameconsole_title;
      var text = '<p>' + window.ls.text_renameconsole_desc + '</p><p>' + window.ls.text_renameconsole_desc2 + '</p>';

      if(el) {
          h1 = window.ls.text_renameconsole_title2;
          //text = '<p>' + window.ls.text_renameconsole_desc_alt + '</p>';
          text = '';
      }

      var html = 	text +
                  '<form id="form_renameconsole" class="wrjs_form">' +
                    '<input type="text" allowblank="true" style="display:none;">' + // THIS IS NEEDED TO STOP FORM AUTO SUBMITTING ON HITTING ENTER
                    '<dl class="rowwed thirtyseventy exactwidths">' +
											'<label class="form-row">' +
												'<dt>' + window.ls.text_name + '</dt>' +
                    		'<dd><input type="text" id="consolename" name="consolename" minlength="2" maxlength="128" value="" class="stripspecialchars"></dd>' +
											'</label>' +
								    '</dl>' +
                  '</form>';

      var options = {
        id:'renameconsole',
        title: h1,
        body: html,
        confirmtext: window.ls.text_save,
        additionalClasses: 'textalign_left blue',
        buttoncolor: 'blue',
        width: 500,
        successconfirm: true,
        animate: true,
        preRenderer: function() {
          setTimeout(function() {
            $('#wrjs_modal_renameconsole .confirmclick').addClass('disabled');
            if(name.length > 0) {
              $('.wrjs_modal #consolename').trigger('focus').val(wrjs.utils.htmlDecode(name));
            } else {
              $('.wrjs_modal #consolename').trigger('focus');
            }
          },50);
        },
        confirmCallback: function (el, modal) {
          _saveConsoleName(el, modal);
        }
      };

      wrjs.View.Modal(options);

		}

  };


	// THIS SAVES THE CONSOLE NAME
  // CALLED FROM msp.global.renameConsole();
  var _saveConsoleName = function(el, modal) {

  	// GET FORM
    var form = $('#form_renameconsole');

    // RESET
    $('.error', form).remove();
    $('.fielderror', form).removeClass('fielderror');

    // CHECK IF VALID
    var valid = wrjs.utils.form.isFormValid(form);

    if(valid) {

      $('.wrjs_modal').mask(window.ls.text_saving);

      var formdata = wrjs.utils.form.getFormData(form);
      var name =  wrjs.utils.htmlEncode(formdata.consolename);

      var success = function(data, textStatus, response) {

        var res = data.responseText;

        if (res && res.indexOf('OK:Your account has been changed') > -1) {

          // SUCCESS
          $('.wrjs_modal').append('<div class="successouter"><i class="successtick"></i>' + window.ls.text_saved + '</div>')

          setTimeout(function() {

          	// GC-5711 - Console Name not getting updated everywhere, therefore reload entire page
			window.location.reload();

			/*
            psMarName = name;
            $('.header-console-name').html(name);
			modal.remove();

			// UPDATE COMPANY NAME ON GSM ACCOUNT TAB, IF IT EXISTS
			var account_companyname = $('#account_companyname');
			if(account_companyname.length > 0) {
				account_companyname.text(name);
			};
			 */

          },700);

        } else {

          // FAIL
          $('.wrjs_modal').unmask();
          var msg = window.ls.text_consolenamegenericerror;

          if(res.indexOf('UNM202') > -1 || res.indexOf('UCM306') > -1) {

              //"Error(UNM202):Name has not changed"
              //"Error(UCM306):Each accou...e a unique account name"
              msg = window.ls.text_consolenamealreadyinuse;

          }

					wrjs.utils.form.markFieldInvalid('#consolename', msg);

          //$('#consolename', form).addClass('fielderror').after('<p class="error wraptext">' + msg + '</p>')

        }

      }

      var config = {
        url: 'marname.aspx',
        timestamp: false,
        params: {
          changemar: 'Y',
          marname: name
        },
        success: success,
        failure: success
      }

      wrjs.Data.makeAjaxCall(config);

    }

	};


	var _termsPresentOnGSM = function() {

		var present = false;
		if(typeof psAgreeTermsConditions  != 'undefined') {
			var agreementpop = $('.agreementpop');
			if(psAgreeTermsConditions == 'True' && agreementpop.length > 0) {
				present = true;
			};
		};
		return present;

	};


	return{
		init: _init,
		renameConsole: _renameConsole,
		termsPresentOnGSM: _termsPresentOnGSM
	}


})(jQuery);

$(document).ready(function(){
	wr.init($.WebrootUI);
});
