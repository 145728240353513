// ***********************************************************
// VIEW
// ***********************************************************

import {
  getLanguageString,
  TEXT_DAY,
  TEXT_DAYS,
  TEXT_HOUR,
  TEXT_HOURS,
  TEXT_MINUTE,
  TEXT_MINUTES,
  TEXT_SECOND,
  TEXT_SECONDS
} from "components/LanguageString";
import moment from "moment";
import pluralize from "../../services/pluralize";

wrjs.View = {


  addToViewManager: function(view){

    wrjs.View.viewManager[view.id] = view;

  },

  registerStoreInViewManager: function(_config){

    var vm = wrjs.View.viewManager;

    // REGISTER THIS GRID ID UNDER THE VIEW MANAGER FOR THIS STORE (STORE USES THIS TO LOOKUP WHICH VIEWS TO UPDATE)
    vm[_config.storeId] = vm[_config.storeId] || [];
    if($.inArray(_config.id, vm[_config.storeId]) == -1){
      vm[_config.storeId].push(_config.id);
    }

  },


  displayFunctions: {

    // USED TO MAKE THE VALUE BE RETURNED AS AN AHREF WITH MATCHING KEY ATTRIBUTE (MD5, URL, FILE, etc)
    addLink: function(value, row, col){

      var str = value;

      // IF MD5 - UPPERCASE AND LOWERCASE LETTERS
      if(col.datakey == 'md5'){
        str = value.toUpperCase();
      }

      if(!str){
        str = col.datakey;
      }

      return '<a href="#nogo" key="' + col.datakey + '">' + str + '</a>';

    },

    addFatLink: function(value, row, col){

      var color = col.id == 'Blocked' ? 'red' : '';

      if(!value){

        return '<span class="fatlink ' + color + '"><b>0</b></span>';

      } else {

        var str = value;
        var num = Number(str);
        var isNumber = !isNaN(num);
        if(isNumber){
          str = wrjs.utils.numberWithCommas(num)
        }

        return '<a class="fatlink ' + color + '" href="#nogo" key="' + col.datakey + '"><em>' + window.ls.text_view +
          '</em><b>' + str + '</b></a>';

      }

    },

    blankDash: function(value){

      if(!value || value.length == 0){
        return '-';
      }

      return value;

    },

    capitalize: function(value, row, col){

      return {
        value: value,
        meta: {
          class: 'capitalize'
        }
      };

    },

    epochToDate: function(value, row, col){
      var v = wrjs.utils.epochtoprettydate({ epoch: value });
      return v;
    },

    numberFormat: function(value, row, col){
      return wrjs.utils.numberWithCommas(value)
    },

    dateFormat: function(value, row, col){

      var dt = new Date();

      if(typeof value == 'object'){
        // DATE OBJECT
        dt = value;
      } else {
        if(typeof value == 'number'){
          // ASSUMING EPOCH
          dt = new Date(value * 1000)
        } else {
          dt = new Date(value);
        }
      }

      // SOME BROWSERS (NOTABLY IE11) NEED A "T" SEPARATING THE DATE AND TIME
      // THEREFORE IF PREVIOUS DATE CREATIONS HAVE FAILED, SPLIT ON SPACES AND ADD THE "T" IN
      if(dt == 'Invalid Date'){
        var dateWithCorrectSeparator = value.split(' ').join('T');
        dt = new Date(dateWithCorrectSeparator);
      }

      var utc = false;

      var days = [window.ls.psMessage95, window.ls.psMessage89, window.ls.psMessage90, window.ls.psMessage91, window.ls.psMessage92, window.ls.psMessage93, window.ls.psMessage94];
      var dates = [window.ls.text_date1st, window.ls.text_date2nd, window.ls.text_date3rd, window.ls.text_date4th, window.ls.text_date5th, window.ls.text_date6th, window.ls.text_date7th, window.ls.text_date8th, window.ls.text_date9th, window.ls.text_date10th, window.ls.text_date11th, window.ls.text_date12th, window.ls.text_date13th, window.ls.text_date14th, window.ls.text_date15th, window.ls.text_date16th, window.ls.text_date17th, window.ls.text_date18th, window.ls.text_date19th, window.ls.text_date20th, window.ls.text_date21st, window.ls.text_date22nd, window.ls.text_date23rd, window.ls.text_date24th, window.ls.text_date25th, window.ls.text_date26th, window.ls.text_date27th, window.ls.text_date28th, window.ls.text_date29th, window.ls.text_date30th, window.ls.text_date31st];
      var months = [window.ls.psMessage96, window.ls.psMessage97, window.ls.psMessage98, window.ls.psMessage99, window.ls.psMessage100, window.ls.psMessage101, window.ls.psMessage102, window.ls.psMessage103, window.ls.psMessage104, window.ls.psMessage105, window.ls.psMessage106, window.ls.psMessage107];

      if(utc){

        var year = dt.getUTCFullYear();
        var month = months[dt.getUTCMonth()].toString();
        var date = dates[dt.getUTCDate() - 1];
        var day = days[dt.getDay()].toString();
        var hour = dt.getUTCHours().toString()
        var min = dt.getUTCMinutes().toString();

      } else {

        var year = dt.getFullYear();
        var month = months[dt.getMonth()].toString();
        var date = dates[dt.getDate() - 1];
        var day = days[dt.getDay()].toString();
        var hour = dt.getHours().toString()
        var min = dt.getMinutes().toString();

      }

      min = min < 10 ? min = '0' + min : min;
      hour = hour < 10 ? '0' + hour : hour;

      var datestring = month + ' ' + date + ' ' + year + ', ' + hour + ':' + min;

      return datestring

    },

    isoDateFormat: function(value, row, col){
      if(value.length == 0){
        return '-';
      } else {
        var d = new Date(value.replace(' ', 'T'));
        return wrjs.View.displayFunctions.dateFormat(d)
      }
    },

    durationFormat: function(value){
      // Get duration
      const duration = moment.duration(value, 'seconds');
      // And begin building string
      let durationString = '';
      // Contains days?
      if(duration.days() > 0){
        durationString += pluralize(duration.days(), {
          one: `1 ${getLanguageString(TEXT_DAY)}`,
          other: `{} ${getLanguageString(TEXT_DAYS)}`
        });
        durationString += ', ';
      }
      // Contains hours?
      if(duration.hours() > 0){
        durationString += pluralize(duration.hours(), {
          one: `1 ${getLanguageString(TEXT_HOUR)}`,
          other: `{} ${getLanguageString(TEXT_HOURS)}`
        });
        durationString += ', ';
      }
      // Contains minutes?
      if(duration.minutes() > 0){
        durationString += pluralize(duration.minutes(), {
          one: `1 ${getLanguageString(TEXT_MINUTE)}`,
          other: `{} ${getLanguageString(TEXT_MINUTES)}`
        });
        durationString += ', ';
      }
      // And finally append seconds
      durationString += pluralize(duration.seconds(), {
        '0': `0 ${getLanguageString(TEXT_SECONDS)}`,
        one: `1 ${getLanguageString(TEXT_SECOND)}`,
        other: `{} ${getLanguageString(TEXT_SECONDS)}`
      });
      return durationString;
    },


    keycode: function(x){
      if(typeof x != 'undefined'){
        return x.toString().replace(/(.{4})(?!$)/g, '$1-').toUpperCase();
      } else {
        return '';
      }
    },

    deviceStatus: function(value, row, col){

      var infected = row.infectednow;
      var expired = row.expired;
      var lastseen = new Date(row.lastseen.replace(' ', 'T')).getTime();
      var dateminusseven = new Date().minusHours(7 * 24).getTime();

      var obj = { meta: { class: [] }, value: '' };

      if(infected == 'Yes'){
        obj.meta.class.push('scan_infected');
        //obj.value = 'Infected <a href="#nogo" style="color:#f00;">View</a>';
        obj.value = window.ls.text_infected;
      } else {
        if(expired == 'Yes'){
          obj.meta.class.push('scan_expired');
          obj.value = window.ls.text_expired;
        } else {
          if(dateminusseven > lastseen){
            obj.meta.class.push('scan_expired');
            obj.value = window.ls.text_notseenrecently;
          } else {
            obj.meta.class.push('scan_clean');
            obj.value = window.ls.text_protected;
          }
        }
      }

      return obj

    },

    determination: function(value, row, col){
      var str = window.ls.text_unclassified;
      switch(value){
        case 'Bad':
        case 'B':
          str = window.ls.text_bad;
          break;
        case 'Good':
        case 'G':
          str = window.ls.text_good;
          break;
        default:
        // DO NOTHIN
      }
      return str;
    },

    dwellTime: function(value, row, col){

      if(value.length == 0){

        return window.ls.text_whitelisted

      } else {

        var str = value;

        str = str.replace('Days', window.ls.text_days);
        str = str.replace('Day', window.ls.text_day);

        str = str.replace('hours', window.ls.text_hours);
        str = str.replace('hour', window.ls.text_hour);

        str = str.replace('mins', window.ls.text_mins);
        str = str.replace('min', window.ls.text_min);

        str = str.replace('secs', window.ls.text_secs);
        str = str.replace('sec', window.ls.text_sec);

        return str;

      }

    },

    noneSpecified: function(value, row, col){
      return value && value.length > 0 ? value : '<em class="light">' + window.ls.text_nonespecified + '</em>';
    },


    dateToTimeAgo: function(value, row, col){

      if(value && value != '0'){

        var now = new Date();
        var dt = new Date();

        if(typeof value == 'object'){
          // DATE OBJECT
          dt = value;
        } else {
          if(typeof value == 'number'){
            // ASSUMING EPOCH
            dt = new Date(value * 1000)
          } else {
            dt = new Date(value);
          }
        }

        // SOME BROWSERS (NOTABLY IE11) NEED A "T" SEPARATING THE DATE AND TIME
        // THEREFORE IF PREVIOUS DATE CREATIONS HAVE FAILED, SPLIT ON SPACES AND ADD THE "T" IN
        if(dt == 'Invalid Date'){
          var dateWithCorrectSeparator = value.split(' ').join('T');
          dt = new Date(dateWithCorrectSeparator);
        }

        var str = '';
        var diff = wrjs.utils.date.dateDiffExtended(now, dt);

        switch(diff.days){

          case 0:

            if(diff.hours > 0){
              str = diff.hours + ' ' + window.ls.text_hoursago;
            } else {
              if(diff.mins > 0){
                str = diff.mins + ' ' + window.ls.text_minsago;
              } else {
                str = window.ls.text_justnow
              }
            }

            break;

          case 1:
            str = window.ls.text_yesterday;
            break;

          default:
            str = diff.days + ' ' + window.ls.text_daysago

        }

        return str;

      } else {

        return '';

      }

    },

    bytesToSize: function (bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return 'n/a';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      if (i == 0) return bytes + ' ' + sizes[i];
      return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    },

    malwareGroup: function(val, row, col, meta, rowIndex, totalRecords) {

      //console.log('MalwareGroup', val, row, meta, rowIndex, totalRecords)

      return val;

    },

    handleHidden: function(val) {

      if(!val || val.length == 0){
        return '-';
      } else {
        var value = val.toLowerCase() == 'hidden' ? window.ls.text_hidden : val;
        return value;
      }

    },

  }

};


// **********************************************************************************
// VIEW MANAGER
// **********************************************************************************

wrjs.View.viewManager = {

  // CONFIG TO GO HERE

};


// **********************************************************************************
// GLOBAL FUNCTION
// **********************************************************************************

if(typeof wrjs.get == 'undefined'){

  wrjs.get = function(id){

    var el = wrjs.Data.storeManager[id] || wrjs.View.viewManager[id];

    // CHECK IT IS ACTUALLY AN OBJECT, AND NOT AN ARRAY OR STRING
    if(typeof el == 'object'){
      if($.isArray(el)){
        el = false;
      }
    } else {
      el = false;
    }

    return el;

  };

}

