import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DOMPurifier from 'dompurify';
import './message.scss';

export default class Message extends Component {

  constructor() {
    super();
    this.state = {
      dismissed: false
    };
  }

  handleMessageDismiss() {
    // CHECK IF A VALID CALLBACK FUNCTION EXISTS AND FIRE IT
    if (this.props.dismissCallback && typeof(this.props.dismissCallback) == 'function') {
      this.props.dismissCallback();
    }

    this.toggleDismissed();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.dismissed) {
      this.toggleDismissed();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.dismissed != nextState.dismissed || this.props != nextProps) {
      return true;
    } else {
      return false;
    }
  }

  toggleDismissed() {
    this.setState({
      dismissed: !this.state.dismissed
    });

    // TRIGGER WINDOW RESIZE TO FIX ANY FULLHEIGTH ELEMENTS
    $(window).resize();
  }

  render(){
    const dismissibleCls = this.props.dismissible ? 'message-is-dismissible' : '';
    const dismissedCls   = this.state.dismissed ? 'message-is-dismissed': '';
    const innerHtmlText = DOMPurifier.sanitize(this.props.text);

    return (
      <div id={this.props.id} className={`wrjs_message message-${this.props.cls} ${dismissibleCls} ${dismissedCls}`} ref={this.messageRef}>
        {
          this.props.icon && (
            <span className={'message-icon fa-' + this.props.icon.replace('la-','') } />
          )
        }
        <div className="message-container" dangerouslySetInnerHTML={{ __html: innerHtmlText }} />
        {
          this.props.dismissible && (
            <div className="message-dismiss fa-times" onClick={this.handleMessageDismiss.bind(this)}></div>
          )
        }
      </div>
    )
  }
};

Message.propTypes = {
  cls: PropTypes.string,
  dismissible: PropTypes.bool,
  dismissCallback: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  icon: PropTypes.string,
  id: PropTypes.string,
  text: PropTypes.string.isRequired
};
