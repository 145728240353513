import isNaN from 'lodash/isNaN';

export default function(count, settings){
  count = Number(count);
  if(isNaN(count) || count === 0){
    return settings['0'];
  }
  else if(count === 1){
    return settings.one
  }
  else {
    return settings.other.replace('{}', count);
  }
};