import '../setPublicPath';
//import '../set-public-path';
// Import uber styles
import './uber.scss';
// Attach the original webroot ui object to the new $ object
import DOMPurify from 'dompurify';

$.WebrootUI = window.$.WebrootUI;
// Expose jQuery
window.$ = $;
window.jQuery = $;

// Set Language
const language = psLangSite == '' ? 'en' : psLangSite.split('-')[0];
const languageStrings = require(`../languages/${language.toLowerCase()}.json`);

global.ls = languageStrings;
global.languageStrings = languageStrings;

// Import common
import '../common/wr';
// Add velocity (enhanced jQuery.animate)
import Velocity from 'velocity-animate';
$.fn.extend({
  velocity: function(options, time){
    return Velocity(this, options, time);
  }
});
// Import only the required wrjs components
import 'components/wrjs/wrjs_utils';
import 'components/wrjs/wrjs_utils_form';
import 'components/wrjs/wrjs_data';
import 'components/wrjs/wrjs_view';
import 'components/wrjs/wrjs_modal';

Date.now = Date.now || function () { return +new Date; };

// STOP CONSOLE.LOG ERRORS

if (!window.console) {
  window.console = new function () {
    this.log = function (str) { };
    this.dir = function (str) { };
  };
}
window.log = function () {
  log.history = log.history || [];   // store logs to an array for reference
  log.history.push(arguments);
  if (this.console) {
    console.log(Array.prototype.slice.call(arguments));
  }
};



function htmlEncode(str) {
  return String(str)
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
}

function htmlDecode(value){
  return String(value)
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&');
}

// CONFIRM POPUP REPLACEMENT

(function ($) {

  $.confirm = function (params) {

    if ($('#confirmOverlay').length) {
      // A confirm is already shown on the page:
      return false;
    }

    var buttonHTML = '';
    $.each(params.buttons, function (name, obj) {
      // Generating the markup for the buttons:
      switch (name) {
        case 'Yes':
          name = ls.psMessage22;
          break;
        case 'No':
          name = ls.psMessage23;
          break;
        default:
          name = ls.psMessage25;
          break;
      }
      buttonHTML += '<a href="#" class="button ' + obj['class'] + '">' + name + '<span></span></a>';
      if (!obj.action) {
        obj.action = function () { };
      }
    });

    var markup = [
      '<div id="confirmOverlay">',
      '<div id="confirmBox">',
      '<h1>', params.title, '</h1>',
      '<p>', params.message, '</p>',
      '<div id="confirmButtons">',
      buttonHTML,
      '</div></div></div>'
    ].join('');

    $(markup).hide().appendTo('body').fadeIn();

    var buttons = $('#confirmBox .button'),
        i = 0;

    $.each(params.buttons, function (name, obj) {
      buttons.eq(i++).click(function () {
        // Calling the action attribute when a
        // click occurs, and hiding the confirm.
        obj.action();
        $.confirm.hide();
        return false;
      });
    });
  }

  $.confirm.hide = function () {
    $('#confirmOverlay').fadeOut(function () {
      $(this).remove();
    });
  }

})(jQuery);





/***********************************************************/
// MASK
/***********************************************************/

(function ($) {

  $.fn.mask = function (label, cls, delay) {
    $(this).each(function () {
      if (delay !== undefined && delay > 0) {
        var element = $(this);
        element.data("_mask_timeout", setTimeout(function () { $.maskElement(element, label) }, delay));
      } else {
        $.maskElement($(this), label, cls);
      }
    });
  };

  $.fn.unmask = function () {
    $(this).each(function () {
      $.unmaskElement($(this));
    });
  };

  $.fn.isMasked = function () {
    return this.hasClass("masked");
  };

  $.maskElement = function (element, label, cls) {

    cls = cls || '';

    if (element.data("_mask_timeout") !== undefined) {
      clearTimeout(element.data("_mask_timeout"));
      element.removeData("_mask_timeout");
    }

    if (element.isMasked()) {
      $.unmaskElement(element);
    }

    if (element.css("position") == "static") {
      element.addClass("masked-relative");
    }

    element.addClass("masked");

    //var elposition = element.css("position");
    //ADE CHANGED BACK 9th march 2015 If the page is scrolled on dashboard and you click a datapoint the mask
    //does not cover the scrolled poriton of the page
    //var maskpos = element.css("position") == "fixed" ? 'absolute !important' : 'fixed !Important';

    // NEW VERSION BY CHRIS - 9TH MARCH 2015
    var maskpos = 'absolute !important';
    if (element[0].nodeName == 'BODY') {
      maskpos = 'fixed !Important';
    }

    // APPLY MASK BORDER RADIUS TO MODAL  ONLY
    var maskradius = element[0].className.indexOf('wrjs_modal') == -1 ? '' : 'border-radius:5px;';

    var maskDiv = $('<div class="loadmask ' + cls + '" style="position:' + maskpos + '; ' + maskradius + '"></div>');

    if (navigator.userAgent.toLowerCase().indexOf("msie") > -1) {
      maskDiv.height(element.height() + parseInt(element.css("padding-top")) + parseInt(element.css("padding-bottom")));
      maskDiv.width(element.width() + parseInt(element.css("padding-left")) + parseInt(element.css("padding-right")));
    }

    if (navigator.userAgent.toLowerCase().indexOf("msie 6") > -1) {
      element.find("select").addClass("masked-hidden");
    }

    element.append(maskDiv);
    //maskDiv.hide().fadeIn(250);
    maskDiv.hide().fadeTo(250, 0.5)


    if(label == false) {
      label = undefined;
    }

    if (label !== undefined) {

      var maskMsgDiv = $('<div class="loadmask-msg ' + cls + '" style="display:none; position:absolute;"></div>');
      maskMsgDiv.append('<div class="loadingmask-spinner"></div> ' + label);

      element.append(maskMsgDiv);

      //maskMsgDiv.css("top", Math.round(element.height() / 2 - (maskMsgDiv.height() - parseInt(maskMsgDiv.css("padding-top")) - parseInt(maskMsgDiv.css("padding-bottom"))) / 2)+"px");
      /*
      var toppx = Math.round(element.height() / 2 - (maskMsgDiv.height() - parseInt(maskMsgDiv.css("padding-top")) - parseInt(maskMsgDiv.css("padding-bottom"))) / 2);
      if(toppx > 600) {toppx = 130}
      maskMsgDiv.css("top", toppx + 'px');
      maskMsgDiv.css("left", Math.round(element.width() / 2 - (maskMsgDiv.width() - parseInt(maskMsgDiv.css("padding-left")) - parseInt(maskMsgDiv.css("padding-right"))) / 2)+"px");
      */

      var w = maskMsgDiv.outerWidth() / 2;
      var h = maskMsgDiv.outerHeight() / 2;
      maskMsgDiv.css({
        left: '50%',
        top: '50%',
        marginTop: '-' + h + 'px',
        marginLeft: '-' + w + 'px'
      })
      maskMsgDiv.fadeIn(250);

    }

  };

  $.unmaskElement = function (element) {
    if (element.data("_mask_timeout") !== undefined) {
      clearTimeout(element.data("_mask_timeout"));
      element.removeData("_mask_timeout");
    }

    element.find(".loadmask-msg,.loadmask").fadeOut(250, function () {
      $(this).remove();
    });
    element.removeClass("masked");
    element.removeClass("masked-relative");
    element.find("select").removeClass("masked-hidden");
  };

})(jQuery);





var uber = {

  init: function () {

    uber.createListeners();

  },

  createListeners: function () {

    // CLICKING THE EMAIL OR PHONE TABS ON LOGIN PAGE (HIKARI)
    $('body').on('click', '.tabs_loginmethod li a', function (e) {
      uber.handleLoginTabs(this, e)
    });

    // DISABLE THE LOGIN FORM BEING SUBMITTED MULTIPLE TIMES
    var loginForm = $('#page_default form[name="changeform"]');
    loginForm.submit(function(){
      loginForm.submit(function(){
        $('input[type="submit"]', loginForm).addClass('disabled');
        return false;
      });
    });

  },
  

  handleLoginTabs: function (el, e) {

    var t = $(el);
    e.preventDefault();

    var parentli = $(t.closest('li'));

    if (!parentli.hasClass('selected')) {

      // REMOVE SELECTED CLASS
      $('.tabs_loginmethod li').removeClass('selected');

      // HIDE BOTH PHONE AND EMAIL DLS
      var els = $('.email, .phone');
      els.hide();

      // CLEAR VALUES FOR PHONE AND EMAIL FIELDS
      $(':input', els).val('');

      // ADD SELECTED CLASS TO CLICKED PARENT LI
      t.parent().addClass('selected');

      // SHOW THE CORRECT DL
      $('.' + el.hash.split('#')[1]).show();

    }

  }

};


uber.lastpass = {

  init: function() {
    uber.lastpass.createListeners()
  },

  createListeners: function() {

    $('body').on('click', '#fakecreatelp', function() {
      uber.lastpass.setupAccountModal();
    })

  },

  setupAccountModal: function() {

    var config = {
      id: 'modal_setuplastpass',
      title: ls.psMessage9,
      body: '<p>' + ls.psMessage10 + '</p>',
      confirmCallback: function() {
        uber.lastpass.getGUID();
      }
    };
    wrjs.View.Modal(config);

  },

  getGUID: function() {

    var modal = wrjs.get('modal_setuplastpass');
    modal.mask(ls.text_loading);

    var cmd = 'UB40SETNEWLPACCESS';

    var config = {
      url: 'uberdetails.aspx?CMD=' + cmd,
      success: function(data) {
        uber.lastpass.openURL(data)
      }
    };
    wrjs.Data.makeAjaxCall(config)

  },

  openURL: function(data) {

    wrjs.View.removeAllModals();

    // Example response:
    // {"success":true,"message":"OK","UberCookieID":null,"LogonApproval":0,"MARType":null,"lpguid":"3d3972c2-ce30-11e8-a1b9-0aa1fff72a52"}

    var guid = data && data.lpguid || '';
    //SK updating URL to use the one controlled by the config
    var url = lastpassurl + '/?usertoken=' + guid;

    // REMOVE ALL MODALS, THEN DISPLAY ANOTHER MODAL TO FORCE THE USER TO CLICK THROUGH TO OPEN A NEW TAB
    // DELAY ENOUGH FOR TEH EXISTING MODAL TO BE REMOVED
    setTimeout(function() {

      var h = languageStrings && languageStrings.text_setuplastpass_header || 'Account Setup';
      var d = languageStrings && languageStrings.text_setuplastpass_description || 'Please click the button below to complete setting up your LastPass account.';
      var b = languageStrings && languageStrings.text_setuplastpass_button || 'Continue';

      var config = {
        title: h,
        body: '<p>' + d + '</p>',
        confirmtext: b,
        confirmCallback: function(button, modal) {
          modal.remove();
          window.open(url);
          $('body').mask(ls.text_loading);
          window.location.reload();
        },
        canceltext: false
      };
      wrjs.View.Modal(config);

    },500)


    //window.location = url;

    /*
    console.log('openURL', data, data.lpguid, url)

    var link = document.createElement('a');
    link.href = url;
    link.target = "_blank";

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    */

  }

}



// UBER CODE

$(document).ready(function () {

  /*
  var w = $(window).outerWidth();
  $('body').prepend('<div id="viewportsize">' + w + 'px</div>');
  $(window).resize(function() {
      var w = $(this).outerWidth();
      $('#viewportsize').text(w + 'px')
  })
  */

  var page = $('body')[0].id;

  // LOGIN PAGE - EMAIL OR PHONE LOGIN
  if(page == 'page_default' || page == 'page_forgotpassword') {
    uber.init();
  };

  // INITIALISE THE LASTPASS LOGIC ON UBER HOME PAGE
  if(page == 'page_home') {
    uber.lastpass.init();
  };

  // FADE OUT REGISTRATION SCREEN TOOLTIPS FOR MOBILE USERS
  $('#signupform input').on('keyup', function () {
    $('.ui-tooltip').fadeOut();
  });

  // STRIP ANGLED BRACKETS <>
  if(page != 'page_gsm') {
    $('body').on('keyup change paste', '.stripspecialchars', function(e) {
      var t = $(this);
      setTimeout(function() {
        var val = t.val();
        var newval = val.replace(/>|</g, '');
        t.val(newval);
      })
    })
  };

  // PREPEND FORM AND MASK TO BODY TO ENSURE ITS ABOVE EVERYTHING ELSE
  if(page == 'page_licenseadd') {
    var form = $('#form_permissionadd');
    if(form.length > 0) {
      $('#mask').prependTo('body');
      form.prependTo('body');
    }
  };

  function centerPopup(el) {
    var w = el.outerWidth();
    var h = el.outerHeight();
    el.css({
      marginLeft: -w / 2,
      marginTop: -h / 2
    })
  };


  // GLOBAL CONFIRM POPUP LOGIC
  function confirmPopup(elem, title, message) {

    var url = $(elem).attr('href');
    if (url == undefined) {
      var confirmtype = 'form';
    } else {
      var confirmtype = 'ahref';
    }

    $.confirm({
      'title': title,
      'message': message,
      'buttons': {
        'Yes': {
          'class': 'yes',
          'action': function () {
            if (confirmtype == 'form') {
              //console.log('FORM')
              var realsubmit = $(elem).siblings('input');
              $(realsubmit).click();
            } else {
              //console.log('AHREF')
              window.location = url;
            }
          }
        },
        'No': {
          'class': 'no',
          'action': function () { }
        }
      }
    });
  }


  // focus the first empty input field on page.
  // ADD THE CLASS OF "NOFOCUS" TO THE PARENT FORM TO STOP AUTO FOCUSING ON PAGE LOAD
  setTimeout(function () {
    var firstinput = $("input[value='']:visible:first");
    var parentform = firstinput.closest('form');
    if (parentform && !parentform.hasClass('nofocus')) {
      $("input[value='']:visible:first").focus();
    }
  }, 300);


  $('.ubertable').each(function (index) {
    $(this).find('tr:even').addClass('even');
  });

  function colorRows() {
    $('table td a.keycodeexpired').closest('tr').addClass('keycodeexpired');
    $('table td a.keycodeexpiring').closest('tr').addClass('keycodeexpiring');
  }
  colorRows();

  // TABS
  $(function () {

    var tabContainers = $('.sub_content');
    tabContainers.hide();
    $('ul#subtabs li a').click(function () {
      tabContainers.hide().filter(this.hash).show();
      $('ul#subtabs li a').removeClass('active');
      $(this).addClass('active');
      return false;
    }).filter('.active').click();

    // GO TO PERMISSIONS TAB IF URL HAS #user_permissions TAGGED ON THE END
    var anchor = global.location.hash;
    if (anchor == "#user_permissions") {
      tabContainers.hide();
      $('ul.tabs li').removeClass('selected');
      $('a[href="' + anchor + '"]').parent().addClass('selected');
      $(anchor).show();
    };
  });


  // SELECTION PAGE
  $('.viewkeycodeinfo').click(function () {
    $(this).parent().parent().next().slideToggle();
    $(this).parent().parent().next().children().children().slideToggle();
  });



  // SYNC AND SHARE POPUP LOGIC
  $('#regionid').click(function () {
    $('.regionpop').fadeIn(500);
    $('#mask').fadeTo(250, 0.6);
  });
  $('#setregion').hide();
  $('#Region').change(function () {
    var value = $(this).find('option:selected').val()
    if (value == '') {
      $('#setregion').hide();
    } else {
      $('#setregion').show();
    }
  });
  $('#setregion').click(function () {
    $('.popup .inner').hide();
    $('.popup h1').hide();
    $('.popup a').hide();
    $('.popup').append('<p class="loadingmessage"><em>' + ls.psMessage18 + '...</em></p>');
  });
  // CENTER ALIGNMENTS
  $('.popup').each(function () {
    $(this).css("marginTop", (-$(this).outerHeight() / 2));
    $(this).css("marginRight", (-$(this).outerWidth() / 2));
  });
  // CLICKING CLOSE MODAL (X) BUTTON
  $('.close').click(function () {
    $('.popup').fadeOut(500);
    $('#mask').fadeOut(250);
    $('#cRegion').val('');
    $('#csetregion').hide();
    $('.popup .inner').show();
    $('.popup h1').show();
    $('.popup a').show();
    $('.loadingmessage').remove();

  });

  // ENSURE ALL POPUP ELEMENTS ARE RENDERED INTO BODY TAG
  var page = $('body')[0].id;
  if(page == 'page_home') {
    $('.popup').prependTo('body');
    $('#mask').prependTo('body');
  };


  // IE7 z-index fix
  //	if ($.browser.msie && parseInt($.browser.version) == 7) {
  //		$('#accountnav').appendTo('.body');
  //	}

  var oldval = $('.hideme').text();
  var whome = psCookieLogon;

  $('.editme').click(function () {
    $('.hideme').text('');
    $('#namewrapper').show();
    $('.editme').hide();
    $('.confirmme').show();
    $('.reset-text').show();
    $('#marname').select();
  })

  $('.reset-text').bind('click', function () {
    $('#marname').focus().val('');
    $(this).hide();
  });

  $('.confirmme').click(function () {

    var newval = $('#marname').val();
    if (newval.length !== 0 && newval !== oldval) {

      $.post(psURLPrefix + psGoSite + 'my.webrootanywhere.com/marname.aspx',
          {
            whome: whome,
            changemar: 'Y',
            marname: newval
          },
          function (data) {
            if (data.indexOf('Error:LogOut') == -1) {
              if (data.indexOf('OK:') !== -1) {
                $('.hideme').text(newval);
                $('#namewrapper').hide();
                $('.editme').show();
                $('.confirmme').hide();
                oldval = newval
              } else {

                if (data.indexOf('Special') !== -1) {
                  alert(data);
                }

                if (data.indexOf('UCM306') !== -1) {
                  if (typeof wrjs == 'undefined') {

                    alert(data);

                  } else {

                    var options = {
                      title: ls.text_error_cannotupdateconsolename,
                      body: '<p>' + ls.text_error_consoleuniquename + '</p>',
                      width: 400,
                      confirmtext: ls.text_ok,
                      canceltext: false
                    };
                    wrjs.View.Modal(options);

                  }
                }


                $('#marname').val(oldval);
                $('.hideme').text(oldval);
                $('#namewrapper').hide();
                $('.editme').show();
                $('.confirmme').hide();
              }
            } else {

              window.location = psURLPrefix + psGoSite + 'my.webrootanywhere.com';

            }
          })
    } else {

      $('.hideme').text(oldval);
      $('#namewrapper').hide();
      $('.editme').show();
      $('.confirmme').hide();

    }
  });




  // CREATE USER PAGE
  var llpcur = $('#lpcheck').is(':checked');
  var lsscur = $('#sscheck').is(':checked');
  var uberPer = $('#TargetUCCAL').val();
  var p4Per = $('#TargetP4CAL').val();
  var SBPer = $('#TargetSBCAL').val();
  var mobilePer = $('#TargetMSCAL').val();
  var webPer = $('#TargetWSCAL').val();


  // CREATE USER
  // USER ACCESS
  // CHECK IF PASSWORDS CHECKBOX IS CHECKED
  // IF SO, SHOW KEYCODE DROPDOWN
  function checkKeycodeState() {
    var checked = $('#lpcheck').is(':checked');
    var keycode = $('.dl_keycode');
    if(checked) {
      keycode.show();
    } else {
      keycode.hide();
    }
  }
  // CHECKBOX CHANGE
  $('body').on('change', '#lpcheck', function() {
    checkKeycodeState();
  });
  // ALSO RUN ON PAGE LOAD
  checkKeycodeState();


  $('.collapsewrap').hide(); // HIDE PERMISSION AND SERVICE DROPDOWNS
  //$('#lpcheck, #sscheck').attr('disabled', ''); // DISABLE BACKUP AND PASSWORD CHECKBOXES
  $('#sscheck').attr('disabled', ''); // DISABLE BACKUP AND PASSWORD CHECKBOXES
  //$('.createuser .button').hide(); // HIDE CREATE BUTTON ON PAGE LOAD
  if ($('.masthead').hasClass('incomplete')) { // IF BINCOMPLETE FLAG IS DETECTED SHOW CREATE BUTTON ON PAGE LOAD
    //$('.createuser .button').show();
  }

  $('.collapser:checked').closest('dl').find('.collapsewrap').show(); // IF ANY CHECKBOXES ARE PRE-CHECKED ON PAGE LOAD (DURING LERRORS, ETC), REVEAL THE RELEVANT DROPDOWN

  $('.collapser').click(function () { // CLICKING A CHECKBOX

    var t = $(this);
    var checked = t.prop('checked');
    var parent = t.closest('.rowwed');
    var cwrap = $('.collapsewrap', parent);
    var $createUser = $('#createuser');

    if (checked) { // SHOWING OR HIDING THE RELEVANT DROPDOWN AREA

      checkservices();
      cwrap.slideDown();
      $createUser.fadeIn();

    } else {

      $createUser.fadeOut();
      cwrap.slideUp();
      var clicked = $(this).attr('id');
      if (clicked == "access") { // WHEN HIDING THE SERVICE DROPDOWN, RESET THE FIELDS
        // RESET CHECKBOXES
        //$('#lpcheck').attr('disabled', '');
        $('#lpcheck').removeAttr('checked');
        $('#sscheck').attr('disabled', '');
        $('#sscheck').removeAttr('checked');
        // RESET SELECT
        $('Select#KeyCode').val('');
        // REMOVE ERROR MESSAGE IF PRESENT
        $(this).parents('dl').next().children('.error').children('dd').children('p').hide();
      } else { // WHEN HIDING THE PERMISSIONS DROPDOWN, RESET THE FIELDS
        // RESET PERMISSIONS
        $('#TargetUCCAL').val(uberPer);
        $('#TargetP4CAL').val(p4Per);
        $('#TargetSBCAL').val(SBPer);
        $('#TargetMSCAL').val(mobilePer);
        $('#TargetWSCAL').val(webPer);
      }
    }
  });

  function checkservices() {
    var lp = $('#KeyCode').find('option:selected').attr('lp'); // GET LP VALUE FROM THE OPTION
    var ss = $('#KeyCode').find('option:selected').attr('ss'); // GET SS VALUE FROM THE OPTION
    var oldkey = $('#KeyCode').find('option:selected').attr('oldkey'); // GET oldkey VALUE FROM THE OPTION

    if (lp == undefined) { // IF ONE LICENSE IN MAR, THEN GET VALUES FROM THE HIDDEN FIELD
      var lp = $('#KeyCode').attr('lp');
      var ss = $('#KeyCode').attr('ss');
      var llpc = $('#KeyCode').attr('lpc'); //meaing already checked on entry to page
      var llpdis = $('#KeyCode').attr('lpd');  //meaing already disabled on entry to page
      var lssc = $('#KeyCode').attr('ssc'); //meaing already checked on entry to page
      var lssdis = $('#KeyCode').attr('ssd'); //meaing already disabled on entry to page
    }

    if (llpc !== "Y" && llpdis !== "Y") {
      if (lp == "Y") {
        //$('#lpcheck').removeAttr('disabled');
      } else {
        //$('#lpcheck').attr('disabled', '');
        $('#lpcheck').removeAttr('checked');
      }
    }

    if (lssc !== "Y" && lssdis !== "Y") {
      if (ss == "Y") {
        $('#sscheck').removeAttr('disabled');
      } else {
        $('#sscheck').attr('disabled', '');
        $('#sscheck').removeAttr('checked');
      }
    }

    $("em").remove(".oldkeycodes");
    if (oldkey !== undefined) {
      if (oldkey !== '') {
        $('<em class="oldkeycodes">' + ls.psMessage12 + ' (' + oldkey + ')</em>').insertAfter('#KeyCode');
      }
    }
  };

  function checkpermissions() {

    p4Per = $('#TargetP4CAL').val();
    if (p4Per !== undefined) {
      if($('#addpermissions').length){$('#user_permissions').addClass('is-admin');}
      if (p4Per > 1) {
        $('#addpermissions').slideDown();
        $('#addalerts').slideDown();
        if(!$('#TargetUCCAL').length){
          $('#user_permissions').addClass('is-sa-admin');
        }
        $('#user_permissions').addClass('is-ep-admin');

      }else {

        $('#addpermissions').slideUp();
        $('#addalerts').slideUp();
        if(!$('#TargetUCCAL').length){
          $('#user_permissions').removeClass('is-sa-admin');
        }
        $('#user_permissions').removeClass('is-ep-admin');
        $('.overridedeter').hide();

        //CLEAR OUT VALS
        $('#addpermissions').find(':input').each(function () {
          if (this.type == 'checkbox') {
            this.checked = false;
          }
          if (this.type == 'radio') {
            this.checked = false;
          }
        });
      }
    }
  };

  function checkagreement() {

    var show = uber.common.termsPresentOnGSM();
    if(show) {

      var m = $('#mask');
      if(m.length == 0) {
        $('body').prepend('<div id="mask"></div>');
      } else {
        m.prependTo('body');
      };

      var agreementpop = $('.agreementpop');

      agreementpop.prependTo('body');
      centerMe(agreementpop);
      agreementpop.fadeIn(500);
      $('#mask').fadeTo(250, 0.6);

      $(window).resize(function() {
        centerMe(agreementpop);
      })

      $('#agreement').click(function () {

        // GET THE AGREEMENT POPUP
        var pop = $('.agreementpop')

        // IF CHECKED, MASK THE AGREEMENT DIV, LOADING..
        pop.mask(ls.psMessage18)

        // POST DO SERVER
        var surl = psURLPrefix + psGoSite + 'my.webrootanywhere.com/pcsecurity.aspx?t=y&version=' + Date.now();
        $.ajax({
          url: surl,
          type: 'GET',
          success: function (data) {

            if (data.indexOf('Error') == -1) {

              // SUCCESS - FADOUT AND REMOVE THE AGREEMENT AND MASK DIVS
              $('#mask').fadeOut(250);
              pop.fadeOut(500, function() {

                pop.remove();
                $('#mask').remove();

              });

              psAgreeTermsConditions = 'False';

            } else {

              // UNMASK POUP
              pop.unmask();

              // ADD SESSION TIMEOUT MESSAGE AND AUTO LOGOFF
              $('#agreementerror').text(ls.psMessage20);
              if (data.indexOf('DOCTYPE html') != -1) { // CHECK IF SESSION TIMED OUT
                window.location = psURLPrefix + psGoSite + 'my.webrootanywhere.com?E=timeout&Result=Error: Your account has been logged out'; // TIMED OUT - REDIRECT
              }

            }
          },

          error: function (response) {

            // ADD SESSION TIMEOUT MESSAGE
            pop.unmask();
            $('#agreementerror').text(ls.psMessage20);

          }
        });

      });

      return false;

    }

  };

  function checkregion() {
    SBPer = $('#TargetSBCAL').val();
    let cregionid = $('#cregionid').val();
    if (SBPer !== undefined) {
      if (SBPer > 0 && cregionid.length == 0) {
        $('.regionpop').fadeIn(500);
        $('#mask').fadeTo(250, 0.6);
        return false;
      }
    }
    return true;
  };

  $('#csetregion').hide();
  $('#cRegion').change(function () {
    var value = $(this).find('option:selected').val()
    if (value == '') {
      $('#csetregion').hide();
    } else {
      $('#csetregion').show();
    }
  });


  checkservices();
  checkpermissions();
  checkregion();
  checkagreement();

  $('#KeyCode').change(function () {
    checkservices();
  });

  $('#TargetP4CAL').change(function () {
    checkpermissions();
  });

  $('#TargetSBCAL').change(function () {
    checkregion();
  });


  //NEW********* USERACCESS - ALT KEY
  $('.altkeyshowhide').hide();
  $('a#toggle').click(function () {
    var toggletxt = $('a#toggle').text();
    if (toggletxt == 'Change?') {
      //open div
      toggletxt = $('a#toggle').text('Revert?');
      $('a#toggle').attr("title", ls.psMessage14);
      var alt = $('#AltKeyCode').attr('alt'); //only one alt key - hiddenfield
      $('a#toggle').addClass('active');
      if (alt !== undefined) {
        if (alt !== '') {
          checkaltservices(); //ensure checkboxes change as required
          $('#AltKeyCode').val(alt); //set hiddenfields value
        }
      }
      $('.altkeyshowhide').show();
    } else {
      //close div and nuke values - revert to old values
      toggletxt = $('a#toggle').text('Change?'); //revert a href text
      $('a#toggle').attr("title", ls.psMessage13);
      $('a#toggle').removeClass('active');
      $('.altkeyshowhide').hide();
      checkservices();
      $('Select#AltKeyCode').val('');
      $('#AltKeyCode').val('');
    }
    return false;
  });

  function checkaltservices() {
    var lp = $('#AltKeyCode').find('option:selected').attr('lp'); // GET LP VALUE FROM THE OPTION
    var ss = $('#AltKeyCode').find('option:selected').attr('ss'); // GET SS VALUE FROM THE OPTION
    var oldkey = $('#AltKeyCode').find('option:selected').attr('oldkey'); // GET oldkey VALUE FROM THE OPTION

    if (lp == undefined) { // IF ONE LICENSE IN MAR, THEN GET VALUES FROM THE HIDDEN FIELD
      var lp = $('#AltKeyCode').attr('lp');
      var ss = $('#AltKeyCode').attr('ss');
    }

    if (lp == "Y" && !llpcur) {
      //$('#lpcheck').removeAttr('disabled');
      $('.dl_keycode').show();
    }

    if (ss == "Y" && !lsscur) {
      $('#sscheck').removeAttr('disabled');
    }

    $("em").remove(".oldkeycodes");
    if (oldkey !== undefined) {
      if (oldkey !== '') {
        $('<em class="oldkeycodes">' + ls.psMessage12 + '(' + oldkey + ')</em>').insertAfter('#AltKeyCode');
      }
    }
  };

  $('#AltKeyCode').change(function () {
    checkservices();
    checkaltservices();
  });
  //NEW********* USERACCESS - ALT KEY

  //HOME PAGE SETTING UP ACCOUNTS
  /*
  $('#createlp').click(function () {
  var r=confirm(ls.psMessage9 + '\n\n' + ls.psMessage10);
  if (r==true) {
  return true;
  }
  else {
  return false;
  }
  });
  $('#createss').click(function () {
  var r=confirm(ls.psMessage11 + '\n\n' + ls.psMessage10);
  if (r==true) {
  return true;
  }
  else {
  return false;
  }
  });
  */

  // CONFIRM DIALOG REPLACEMENTS - CW
  /*
  $('#fakecreatelp').click(function () {
    confirmPopup(this, ls.psMessage9, ls.psMessage10)
  });
  */

  $('#fakecreatess').click(function () {
    confirmPopup(this, ls.psMessage11, ls.psMessage10)
  });

  $('#syncshareinfo').click(function () {

    $.confirm({
      'title': ls.psMessage26,
      'message': ls.psMessage27,
      'buttons': {
        'Close': {
          'action': function () { return false }
        }
      }
    });

    return false

  });


  //User Access permissions
  $('#fakepermissions').click(function () {
    if (checkregion()) {
      var UC = $('#UCCAL').val();
      var UCD = $('#TargetUCCAL').val();
      var Count = 0;
      var llp = $('#lpcheck').is(':checked');
      var lss = $('#sscheck').is(':checked');
      var keycode = $('#KeyCode').val();
      var AltKeyCode = $('#AltKeyCode').val();
      var ii = 0;
      var rr;
      var rrr;

      if (llp !== llpcur) {
        ii++;
      }
      if (lss !== lsscur) {
        ii++;
      }

      if ((keycode !== '') && (llp !== llpcur || lss !== lsscur)) {
        if (ii == 1) {
          confirmPopup(this, ls.psMessage2, ls.psMessage3)
          //rr=confirm(ls.psMessage2 + '\n\n' + ls.psMessage3);
        }
        if (ii == 2) {
          confirmPopup(this, ls.psMessage4, ls.psMessage5)
          //rr=confirm(ls.psMessage4 + '\n\n' + ls.psMessage5);
        }
        if (rr == true) {
          return true;
        }
        else {
          return false;
        }
      }

      if (AltKeyCode !== undefined) {
        if (AltKeyCode !== '') {
          confirmPopup(this, ls.psMessage6, '')
          return false;
          /*
          rrr=confirm(ls.psMessage6);
          if (rrr==true) {
          return true;
          }
          else {
          return false;
          }
          */
        }
      }

      if (parseInt(UC) > parseInt(UCD)) {
        Count++;
      }
      if (Count > 0) {
        confirmPopup(this, ls.psMessage7, '')
        return false;
        /*
        var r=confirm(ls.psMessage7);
        if (r==true) {
        return true;
        }
        else {
        return false;
        }
        */
      }
      $('#permissions').click();
    } else {
      return false;
    }
  });

  //User Access permissions
  $('#fakeuserpermissions').click(function () {
    if (checkregion()) {
      var llp = $('#lpcheck').is(':checked');
      var lss = $('#sscheck').is(':checked');
      var keycode = $('#KeyCode').val();
      var AltKeyCode = $('#AltKeyCode').val();
      var ii = 0;
      var rr;

      if (llp !== llpcur) {
        ii++;
      }
      if (lss !== lsscur) {
        ii++;
      }

      if ((keycode !== '') && (llp !== llpcur || lss !== lsscur)) {
        if (ii == 1) {
          confirmPopup(this, ls.psMessage2, ls.psMessage3)
          //rr=confirm(ls.psMessage2 + '\n\n' + ls.psMessage3);
        }
        if (ii == 2) {
          confirmPopup(this, ls.psMessage4, ls.psMessage5)
          //rr=confirm(ls.psMessage4 + '\n\n' + ls.psMessage5);
        }
        if (rr == true) {
          return true;
        }
        else {
          return false;
        }
      }

      if (AltKeyCode !== undefined) {
        if (AltKeyCode !== '') {
          confirmPopup(this, ls.psMessage6, '')
          return false;
          /*
          rrr=confirm(ls.psMessage6);
          if (rrr==true) {
          return true;
          }
          else {
          return false;
          }
          */
        }
      }
      $('#userpermissions').click();
    } else {
      return false;
    }
  });

  //User Access permissions
  $('#fakecreateuser').click(function () {
    if (checkregion()) {
      var email = $('#Email').val();
      var llp = $('#lpcheck').is(':checked');
      var lss = $('#sscheck').is(':checked');
      var keycode = $('#KeyCode').val();
      var ii = 0;
      var rr;

      if (llp) {
        ii++;
      }
      if (lss) {
        ii++;
      }

      if ((keycode !== '') && (llp || lss) && (email !== '')) {
        if (ii == 1) {
          confirmPopup(this, ls.psMessage2, ls.psMessage3)
          //rr=confirm(ls.psMessage2 + '\n\n' + ls.psMessage3);
        }
        if (ii == 2) {
          confirmPopup(this, ls.psMessage4, ls.psMessage5)
          //rr=confirm(ls.psMessage4 + '\n\n' + ls.psMessage5);
        }
        if (rr == true) {
          return true;
        }
        else {
          return false;
        }
      }
      //return true;
      $('#createuser').click();
    } else {
      return false;
    }

    //$('#form1').submit();
  });


  // TEMP MOBILE NOGO BUTTON
  $('.nomobilego').click(function () {
    alert(ls.psMessage8)
  });





  //ALERTS
  $('.alerttype').change(function () {
    var Drop = $(this).attr('id');
    var Val = $(this).val();

    if (Val.substring(0, 2) == "SC") {
      $('#' + Drop.substring(2) + 'basex').show();
      $('#' + Drop.substring(2) + 'freqx').show();
    }
    if (Val.substring(0, 2) == "AL" || Val.substring(0, 2) == "") {
      $('#' + Drop.substring(2) + 'basex').hide();
      $('#' + Drop.substring(2) + 'freqx').hide();
      $('#' + Drop.substring(2) + 'base').val('');
      $('#' + Drop.substring(2) + 'freq').val('');
    }
  });


  // SME OVERRIDE MD% PERMISSIONS
  function showhidemd5() {
    if ($('.md5access').is(':checked')) { // SHOWING OR HIDING THE CREATE USER BUTTON
      $('.overridedeter').show();
    } else {
      $('.overridedeter').hide();
      //$('#PCOvers').val('');
    }
  }
  showhidemd5()
  $('.md5access').click(function () {
    showhidemd5()
  });



  // LANGUAGE DROPDOWN
  $('#langdrop_msdd').click(function () {
    $('#languageholder').toggleClass('flat')
  });

  // LOGIN - PASSWORD TOGGLE
  $('.password-toggle').click(function(e){
    var $this = $(this);
    var $passwordInput = $this.parent().children('input');

    $this.toggleClass('password-is-shown');
    if($this.hasClass('password-is-shown')){
      $passwordInput.prop('type', 'text');
    }else{
      $passwordInput.prop('type', 'password');
    }
  });

  $(document).click(function (e) {

    var target = $(e.target);
    var parent = target.parents().is('#languageholder')
    if (!parent) {
      $('#languageholder').removeClass('flat');
    }

    $('.tooltip_hover').removeClass('show');

  });


  // SELECTION.ASP
  $('body').on('click', 'a.showkeys', function (e) {

    e.preventDefault();

    var t = $(this);
    var whome = psCookieLogon;
    var marid = t.attr('marid');
    var target = t.closest('tr');
    var targetrow = target.next();

    if (targetrow.hasClass('keyinfo')) {
      var vis = target.next().find('.keycodeinner').is(':visible');
      if (vis) {
        target.next().slideUp();
        target.next().find('.keycodeinner').slideUp();
      } else {
        target.next().find('.keycodeinner').hide();
        target.next().slideDown();
        target.next().find('.keycodeinner').slideDown();
      }
    } else {

      target.after('<tr class="keyinfo"><td class="cellholder" colspan="3"><div class="keycodeinner"><div class="loader-container"><div class="loader">' + ls.psMessage29 + '</div></div></div></td></tr>');
      $.ajax({
        type: 'POST',
        url: '/selectionkeys.aspx?version=' + Date.now(),
        data: {
          marid: marid,
          whome: whome
        },
        success: function (data) {
          var logoff = data.indexOf('<!DOCTYPE html>');
          if (logoff == -1) {

            var kinner = target.next().find('.keycodeinner');

            function insertandfadein() {
              setTimeout(function () {
                kinner.html(data).slideDown();
                colorRows();
              }, 500)
            }
            kinner.slideUp('500', insertandfadein());
          } else {
            window.location = psURLPrefix + psGoSite + 'my.webrootanywhere.com/logoff.aspx?Result=Error: Your%20account%20has%20been%20logged%20out';
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          //console.log('Failure')
          //console.log(jqXHR, textStatus, errorThrown)
        }
      });
    }
  })



  function GetAccessLevel(ucaccesslevel) {

    accesslevel = ls.psMessage32;

    switch (ucaccesslevel) {
      case 128:
        accesslevel = ls.psMessage30; //needs language translation
        break;
      case 1:
        accesslevel = ls.psMessage31;
        break;
      default:
        accesslevel = ls.psMessage32;
        break;
    }
    return accesslevel;
  }





  // LICENSEADD

  var licaddform = $('#form_licenseadd');

  if (licaddform.length > 0) {

    var ent = 0;
    var sync = 0;
    var bby = 0;

    /*
    $('#dterms').hide();
    $('#pterms').hide();
    $('#dregion').hide();
    $('#pregion').hide();
    */

    var key_addlicense = $('#form_licenseadd #KeyCode').val();

    if (key_addlicense) {
      if (key_addlicense.length == 0) {
        $('#ent').val('0');
        $('#reg').val('0');
        $('#dterms').hide();
        $('#dregion').hide();
        $('#pterms').hide();
        $('#pregion').hide();
      }
    }

    var errormessage = $('#errormessage');

    if (errormessage.length != 0) {
      // Error message exists
      $('#ent').val('0');
      $('#reg').val('0');
      $('#dterms').hide();
      $('#dregion').hide();
      $('#pterms').hide();
      $('#pregion').hide();

    } else {

      if ($('#ent').val() == '1') {

        var Ts = $('#checkbox_terms').is(':checked');

        if (!Ts) {

          //$('#dterms .mockinput').addClass('fielderror');
          $('#dterms').fadeIn(500).promise().done(function () {
            $('#pterms').slideDown();

          })
        } else {
          $('#dterms').fadeIn(500);
        }

        //$('#KeyCode').attr('readonly', 'Y').addClass('input_readonly');
        //$('#resetlicense').fadeIn();
      }


      if ($('#reg').val() == '1') {

        var Regions = $('#Region').find('option:selected').val()
        if (Regions == '') {
          $('#dregion').addClass('error message');
          $('#dregion').fadeIn(500).promise().done(function () {
            $('#pregion').slideDown();
          })
        } else {
          $('#dregion').fadeIn(500);
        }
        //$('#KeyCode').attr('readonly', 'Y').addClass('input_readonly');
        //$('#resetlicense').fadeIn();
      }

    }

    function addKeycodeError() {

      $('#KeyCode').removeAttr('readonly');
      $('#ent').val('0');
      $('#reg').val('0');
      $('#dterms').hide();
      $('#dregion').hide();
      $('#pterms').hide();
      $('#pregion').hide();
      $('#KeyCode').addClass('fielderror').next('p').remove();
      $('#KeyCode').after('<p class="fielderror">' + ls.psMessage24 + '</p>').hide().fadeIn();
      $('#KeyCode').next('p').hide().fadeIn();

    }

    $('#resetlicense').click(function () {

      $('input.fielderror').removeClass('fielderror');
      $('p.fielderror').remove();

      $('#ent').val('0');
      $('#reg').val('0');
      $('#dterms').hide();
      $('#dregion').hide();
      $('#pterms').hide();
      $('#pregion').hide();
      $('#KeyCode').removeAttr('readonly').removeClass('input_readonly').val('').focus();

      $(this).hide();
    })

    var forcesubmit = 'N';
    /*
        $('#form_licenseadd').submit(function () {

            //console.log('SUBMIT');

            $('#KeyCode').attr('readonly', 'Y');

            var key = $('#KeyCode').val();
            key = key.replace(/\s/g, '');
            $('#KeyCode').val(key)

            if (errormessage.length != 0) { errormessage.remove() }

            if (key.length == 20 || key.length == 24 || key.length == 36) {

                if ($('#ent').val() == '1' || $('#reg').val() == '1' || forcesubmit == 'Y') {
                    // THIS IS JUST FOR VISUAL CONSISTENCY
                    //if(psRegionID != 0 && sync != 0 || forcesubmit == 'Y') {
                    if (forcesubmit == 'Y') {
                        $('#addlicense').before('<p id="checkingkeycode">' + ls.text_checkingKeycode + '</p>');
                    }
                    return true

                } else {


                    $('#addlicense').before('<p id="checkingkeycode">' + ls.text_checkingKeycode + '</p>');

                    var surl = psURLPrefix + psGoSite + 'my.webrootanywhere.com/aboutkey.aspx?K=' + key + '&version=' + Date.now();
                    $.ajax({
                        url: surl,
                        type: 'GET',
                        success: function (data) {

                            $('#checkingkeycode').remove();
                            $('#addlicense').fadeIn();

                            if (data.indexOf('Error') == -1) {

                                $('input.fielderror').removeClass('fielderror');
                                $('p.fielderror').remove();

                                $('#KeyCode').removeAttr('readonly');

                                $('#pterms').hide();
                                $('#pregion').hide();

                                var n = data.split(",");
                                ent = n[0];
                                sync = n[1];
                                bby = n[2];

                                var newconsoleyes = $('#NewConsoleYes').is(':checked');

                                if (ent == 1 || sync == 1 || bby == 1) {
                                    if (ent == 1) {
                                        //console.log(psAgreeTermsConditions)
                                        if (newconsoleyes) {
                                            $('#ent').val('1');
                                            $('#dterms').fadeIn();
                                            //$('#KeyCode').attr('readonly', 'Y').addClass('input_readonly');
                                            //$('#resetlicense').fadeIn();
                                        } else {
                                            if (psAgreeTermsConditions == 'False') {
                                                if (psConsoleType == 'E') {
                                                    forcesubmit = 'Y';
                                                    $('#addlicense').click();
                                                } else {
                                                    $('#ent').val('1');
                                                    $('#dterms').fadeIn();
                                                    //$('#KeyCode').attr('readonly', 'Y').addClass('input_readonly');
                                                    //$('#resetlicense').fadeIn();
                                                }
                                            } else {
                                                $('#ent').val('1');
                                                $('#dterms').fadeIn();
                                                //$('#KeyCode').attr('readonly', 'Y').addClass('input_readonly');
                                                //$('#resetlicense').fadeIn();
                                            }
                                        }

                                    } else {

                                        //if sync on key, no region - ask
                                        //if sync on key and best buy and not a current Best Buy console - ask
                                        //current console not best buy, key is best buy set region to 0
                                        //current console is best buy, key is not best buy set region to 0
                                        //if they ask for new console set region to 0

                                        if ((bby == 1 && psBBY == '0') || (bby == 0 && psBBY != '0') || newconsoleyes) {
                                            psRegionID = 0;
                                        }
                                        if (sync == 1 && psRegionID == 0) { //|| (sync == 1 && bby == 1)) {
                                            $('#Region').val('');
                                            $('#reg').val('1');
                                            $('#dregion').fadeIn();
                                            //$('#KeyCode').attr('readonly', 'Y').addClass('input_readonly');
                                            //$('#resetlicense').fadeIn();
                                            //creating a new console, clear region otherwise we submit in next statement
                                            psRegionID = 0;
                                        }

                                        // REGIONID ALREADY SET
                                        // RETURN FALSE HAS ALREADY FIRED, THEREFORE SET VALUES AND SUBMIT FORM AGAIN TO RETURN TRUE
                                        if (psRegionID != 0 && ent != 1) {
                                            $('#Region').val(psRegionID);
                                            $('#reg').val('1');
                                            forcesubmit = 'Y';
                                            $('#addlicense').click();
                                        }

                                        if (sync == 0 && bby == 1) {
                                            forcesubmit = 'Y';
                                            $('#addlicense').click();
                                        }
                                    }

                                } else {

                                    // REMOVED AS THIS WAS CAUSING ISSUES WHEN ADDING AV AND MOB KEYS (0,0,0)
                                    // addKeycodeError()

                                    // RETURN FALSE HAS ALREADY FIRED, THEREFORE SET FORCESUBMIT VALUE AND SUBMIT FORM AGAIN TO RETURN TRUE
                                    forcesubmit = 'Y';
                                    $('#addlicense').click();

                                }

                            } else {
                                addKeycodeError()
                            }
                        }
                    });

                    return false
                }
            } else {

                addKeycodeError()
                return false

            }
        })

    */

  }




  // NEW SYNC BACKUP POLLING LOGIC


  var accAccess = $('#beingprocessed').length;

  if (accAccess != 0) {

    //console.log('NO SB ACCOUNT READY')

    function checkSBStatus() {
      $.ajax({
        url: psURLPrefix + psGoSite + 'my.webrootanywhere.com/syncshare.aspx?P=Y&version=' + Date.now(),
        type: 'GET',
        dataType: 'json',
        success: function (data) {

          switch (data[0].SBAccountAccess) {

            case '1':
            case '2':
              // ACCOUNT NOT READY - POLL
              //console.log(data[0].SBAccountAccess, ': Account Pending - Polling')
              setTimeout(function () {
                checkSBStatus()
              }, 30000)
              break;

            case '3':
              // FAILURE - CONTACT SUPPORT
              //console.log('Account Creation Failure')
              window.location.reload()
              break

            case '4':
              // ACCOUNT CREATED - NOW CHECK USER STATUS
              //console.log('Account Created - Now Checking User')

              switch (data[0].SBUserAccess) {

                case '1':
                case '2':
                  //console.log(data[0].SBUserAccess, ': User Pending - Polling')
                  setTimeout(function () {
                    checkSBStatus()
                  }, 30000)
                  break;

                case '3':
                  // FAILURE - CONTACT SUPPORT
                  //console.log('User Creation Failure')
                  window.location.reload()
                  break

                case '4':
                  // USER CREATED
                  //console.log('Account and User Created - Checking Complete')
                  window.location.reload()
              }

              break

            default:
              //console.log('Default')
              break

          }

        },
        error: function (response) {
          //console.log('Failure')
          //console.log(response);
        }
      });
    }

    checkSBStatus()

  }


  // NEW LP POLLING LOGIC
  var lpaccAccess = $('#lpbeingprocessed').length;
  if (lpaccAccess != 0) {
    //console.log('NO LP ACCOUNT READY')
    function checkLPStatus() {
      $.ajax({
        url: psURLPrefix + psGoSite + 'my.webrootanywhere.com/lastpass.aspx?P=Y&version=' + Date.now(),
        type: 'GET',
        dataType: 'json',
        success: function (data) {
          switch (data[0].LPStatus) {
            case '4':
            case '7':
              // ACCOUNT TRANSITION PENDING, CHECK STATE EVERY 15 SECONDS
              setTimeout(function() {
                checkLPStatus();
              },20000)
              break;
            case '8':
              // ACCOUNT CREATED - NOW CHECK USER STATUS
              //console.log('Account Created')
              window.location.reload()
              break;
            default:
              //console.log('Default')
              break
          }
        },
        error: function (response) {
          //console.log('Failure')
          //console.log(response);
        }
      });
    }
    checkLPStatus()
  }


  $('.module.syncshare').on('click', '#callregionpopup', function () {
    $('.regionpop').fadeIn(500);
    $('#mask').fadeTo(250, 0.6);
    return false
  })

  $('#showregion select#TargetSBCAL').change(function () {
    var val = $(this).val();
    if (val > 0) {
      $('#regiondl').slideDown();
    } else {
      $('#regiondl').slideUp();
      $('#Region').val('');
    }
  })

  // STOP FORM SUBMITTING WHEN HITTING ENTER BEFORE FILLING IN ANY OTHER INFORMATION ON CREATEUSER.ASPX
  $('.createuser :input:not(:button)').keypress(function (e) {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  });










  /*****************************************************************************/
  // ONLY ALLOW THE FORM TO SUBMIT ONCE ON TRUST.ASP (DISABLES MULTIPLE CLICKS)
  /*****************************************************************************/

  var submitCount = 0;
  function submitOnce(form, e) {
    if (submitCount == 0) {
      var submit = form.find(':submit');
      submit.addClass('disabled');
      submitCount = 1;
    } else {
      e.preventDefault();
    }
  }

  // CHECK IF WE HAVE ANY OF THE SINGLE SUBMIT FORMS ON PAGE
  var subs = $('.form_submitonce');
  if (subs.length > 0) {
    // IF SO, PRELOAD THE LOADING GIF (otherwise this doesn't have chance to load before the form is submitted)
    $('body').append('<div class="loadinggif"></div>')
  }
  subs.submit(function (e) {
    submitOnce($(this), e)
  });

  $('.awesome.loader').click(function () {
    $(this).prop('disabled', true).addClass('disabled')
  })





  // *****************************************************
  // PERMISSIONS POPUP AFTER ADDING NEW KEYCODE
  // *****************************************************

  function centerMe(el) {
    var w = el.outerWidth();
    var h = el.outerHeight();
    el.css({
      marginLeft: -w / 2,
      marginTop: -h / 2
    })
  }

  var permissionpopup = $('.permissionpopup').length;
  if (permissionpopup > 0) {
    $('#mask').fadeTo(500, 0.7);
    $('.permissionpopup').fadeTo(750, 1);
    centerMe($('.permissionpopup'))
  }

  var autopop = $('.autopop').length;
  if (autopop > 0) {
    $('#mask').fadeTo(500, 0.7);
    $('.autopop').fadeTo(750, 1);
    centerMe($('.autopop'))
  }

  $('#permission_reject:not(.disabled)').click(function () {
    $('.wrjs_modal').mask(ls.text_loading);
    $('.wrjs_modal .button').addClass('disabled').prop('disabled', true);
    setTimeout(function () {
      window.location = 'licenseview.aspx';
    }, 1);
  });

  var permissionValidation = {
    p4: $('input[name="LP4Access"]'),
    mob: $('input[name="LMSAccess"]'),
    backup: $('input[name="LSBAccess"]'),
    pass: $('input[name="LLPAccess"]'),
    submittable: function () {
      var submittable = false;
      if (this.mob.prop('checked') == true || this.backup.prop('checked') == true || this.pass.prop('checked') == true) {
        submittable = true;
      } else {
        submittable = false;
      }
      if (this.p4.length > 0) { submittable = true }
      var button = $('#permission_add');
      if (submittable) {
        button.removeAttr('disabled').removeClass('disabled');
      } else {
        button.attr('disabled', 'disabled').addClass('disabled');
      }
    },
    setup: function () {
      $('input[name="LMSAccess"], input[name="LSBAccess"], input[name="LLPAccess"]').on('change', function () {
        permissionValidation.submittable();
      });
      this.submittable();
    }
  };
  if (permissionValidation.p4.length > 0 || permissionValidation.mob.length > 0 || permissionValidation.backup.length > 0 || permissionValidation.pass.length > 0) {
    permissionValidation.setup();
  }








  // ****************************************************************
  //
  // SELECTION PAGE - BUILD TABLES
  //
  // ****************************************************************

  var selectionpage = $('#page_selection').length > 0;
  if (selectionpage) {
    selpage.init();
  }

  // ****************************************************************
  //
  // RESEND EMAIL FOR SHOW USERS
  //
  // ****************************************************************

  $('body').on('click', '.resendemail', function () {

    var t = $(this);
    var tab_admins = t.closest('#tab_admins');

    // IF NOT GSM PERFORM THIS ACTION (GSM HAS A CLASS WHICH ALSO PERFORMS THIS SAME ACTION - CONTROLLED IN MSP_ADMINS)
    if (tab_admins.length == 0) {

      var ubid = t.attr('ubid');

      t.hide();
      t.after('<span class="resendconfirm sending">' + ls.text_sending + '...</span>')

      $.ajax({
        url: 'showusers.aspx?u=' + ubid + '&R=Y&whome=' + psCookieLogon,
        dataType: 'json',
        success: function (data) {
          t.next().remove();
          t.after('<span class="resendconfirm tick">' + ls.text_emailconfirmsent + '</span>')
          t.remove();
        }
      });

    }

  })

  // ****************************************************************
  //
  // DELETE USER FOR SHOW USERS
  //
  // ****************************************************************

  $('.userdelete').click(function (e) {

    e.preventDefault();
    var t = $(this);
    var ubid = t.attr('ubid');
    var pn = t.attr('pn');
    var x = e.target.id;

    $.confirm({
      'title': ls.text_suredeleteadmin,
      'message': ls.text_deleteadmindesc1,
      'buttons': {
        'Yes': {
          'class': 'yes',
          'action': function () {
            var y = x;
            var h = x.replace("d", "e");
            var b = y.replace("d", "r");
            t.hide();
            $("#" + h).hide();
            $("#" + b).hide();
            t.after('<span class="resendconfirm sending">' + ls.text_removing + '...</span>')
            $.ajax({
              url:'showusers.aspx?D=Y&pn=' + pn,
              type:'POST',
              dataType: 'json',
              data: {
                whome: psCookieLogon,
                pn: pn,
                u: ubid
              },
              success: function (data) {
                window.location.reload(true)
              }
            });
          }
        },
        'No': {
          'class': 'no',
          'action': function () { }
        }
      }
    });
  })

  // ****************************************************************
  //
  // EDIT USER FOR SHOW USERS
  //
  // ****************************************************************
  $('body').on('click', '.useredit', function () {

    var t = $(this);
    var ubid = t.attr('ubid');
    var url = 'useraccess.aspx?u=' + ubid + '&S=Y&whome=' + psCookieLogon;
    window.location = url;

  })



  // ****************************************************************
  //
  // TOOLTIP POPUP
  //
  // ****************************************************************


  if(location.pathname != '/gsm.aspx') {

    var $body = $('body');

    // TOOLTIP POPUP
    $body.on('mouseenter', '.tooltip_icon, .hovertip', function () {

      var t = $(this);
      var title = t.attr('tooltip');
      var position = t.attr('tooltip_position') || '';
      var html = '<p>' + title + '</p>';

      // GET POSITIONS
      var lpos = t.attr('lpos') ? t.offset().left + Number(t.attr('lpos')) : t.offset().left;
      var tpos = t.attr('tpos') ? t.offset().top + Number(t.attr('tpos')) : t.offset().top - 7;

      // BUILD CLASS STRING
      var cls = [];
      if(t.hasClass('bluehover')) {
        cls.push('blue')
      };
      var tcls = t.attr('tcls') || '';
      if(tcls.length > 0) {
        cls.push(tcls);
      };
      var colorcls = cls.join(' ');

      var tooltip = $('.tooltip_hover');
      if(tooltip.length == 0) {
        $('body').append('<div class="tooltip_hover"></div>');
        tooltip = $('.tooltip_hover');
      }

      var arrow = position == 'position_right' ? 'arrow_right' : 'arrow_left';

      html = '<div class="' + arrow + '"></div>' + html;
      tooltip.empty().html(html).removeClass().addClass('tooltip_hover show ' + colorcls + ' ' + position);

      var posright = position == 'position_right';

      var rpos = 'auto';
      if(posright) {
        var w = tooltip.outerWidth();
        lpos = lpos-(w+9);
        rpos = $(document).outerWidth() - t.offset().left + 7;
      };

      if(posright) {

        tooltip.css({
          right: rpos,
          left: 'auto',
          top: tpos
        });

      } else {

        tooltip.css({
          left: lpos,
          right: 'auto',
          top: tpos
        });

      };



    });

    $body.on('mouseenter', '.tooltip_hover', function () {
      $(this).addClass('show');
    });

    $body.on('mouseleave', '.tooltip_hover, .tooltip_icon, .hovertip', function (e) {

      var popup = $('.tooltip_hover');
      popup.removeClass('show');

      /*
      // RESET THIS TOP TOP LEFT AFTER FADEOUT TO ENSURE IT DOESN@T AFFECT FLOW (SCROLLBARS)
      // THIS NEEDS TO MATCH THE TOOLTIP_HOVER CSS TRANSITION VALUE
      var duration = 600;
      setTimeout(function() {
        var p = $('.tooltip_hover:not(.show)');
        if(p.length > 0) {
          p.css({
            left: 0,
            top: 0
          })
        }
      }, duration)
      */

    });

    // THIS IS TO STOP TABLE HOVER STATES WHEN HOVERING OVER CHILD ELEMENTS (BUTTONS, ETC)
    $body.on('mouseenter', '.ceasehover', function (e) {
      $(this).closest('tr').addClass('ceasehover');
    });

    $body.on('mouseleave', '.ceasehover', function() {
      $(this).closest('tr').removeClass('ceasehover');
    });

  };







  //****************************************************************
  // MEASURE MENU
  //
  // THIS IS USED TO MEASURE THE NAV AND CLICKME ITEMS, AND CHECK IF THEY ARE OVERLAPPING
  // IF SO, APPEND A CLASS TO THE BODY TO FORCE THEM TO CLEAR
  //
  //****************************************************************


  $('body').on('click', 'li#account', function() {
    $('body').toggleClass('menuswoosher');
  })

  $(window).on('resize', function() {

    //console.log('RESIZE');
    uber.common.measureMenu();

    var autopop = $('.autopop');
    if(autopop.length > 0) {
      centerMe(autopop);
    }

  })




});





var selpage = {

  init: function() {

    const language = psLangSite == '' ? 'en' : psLangSite.split('-')[0];
    const languageStrings = require(`../languages/${language.toLowerCase()}.json`);

    global.ls = languageStrings;

    //console.log('INIT')

    selpage.createListeners();

    var loadinghtml = '<div class="loader-container loadingbar"><div class="loader">' + ls.text_css_loadingconsoles + '</div></div>';
    $('#contentarea').append('<div class="xs-wrapper wrapper">' + loadinghtml + '</div>');

    selpage.getData();

  },

  createListeners: function() {

    $('body').on('click', '.paging ul li a:not(.disabled)', function(e){

      e.preventDefault();

      $('#grid_consoles').mask(ls.text_loading, 'white');

      var t = $(this);
      var pagenumber = t.attr('page');
      selpage.currentpage = pagenumber;

      $('#consoletable').mask(ls.text_loading, 'white');
      $('.paging').mask(false, 'white');

      selpage.getData(pagenumber);

      return false;

    })

        .on('click','.console', function(e){
          // CONSOLES TAKE SOMETIME TO LOAD SO ON CLICK OF A CONSOLE NAME, DISPLAY LOADING MASK TO INIDICATE TO THE USER SOMETHING IS HAPPENING
          $('body').mask(ls.text_loading, 'white');
        })

  },

  errorHandler: function(jqXHR, statusText) {

    if(statusText != 'error') {
      $('.loadingbar').remove();
      $('#contentarea .wrapper').prepend('<p class="error pad">' + ls.text_css_loadingerror + '</p>');
    }

  },

  gsmConsoles: false, // VARIABLE TO DETERMINE IF TWO COLUMN LAYOUT IS REQUIRED TO DISPLAY BOTH GSM & EP CONSOLES SIDE BY SIDE

  getData: function(pagenumber) {

    var consoleonly = typeof pagenumber != 'undefined' ? true : false;
    var pn = consoleonly ? pagenumber : '1';

    $.ajax({
      url:'uberdetails.aspx?CMD=UB40SEL&PN=' + pn,
      type:'POST',
      dataType: 'json',
      data: {
        whome: psCookieLogon,
        pn: pn
      },
      success: function(data, statusText, jqXHR) {

        $('.loadingbar').remove();

        if(data.success) {

          $.extend(selpage, data);

          if(!consoleonly) {
            // BUILD GSM TABLE
            if(selpage.msprecords && selpage.msprecords.length > 0) {
              selpage.gsmConsoles = true;
              selpage.buildGSMTable(selpage.msprecords);
            }
          }

          // BUILD CONSOLE TABLE
          if(selpage.records && selpage.records.length > 0) {
            selpage.buildConsoleTable(selpage.records);
          }


        } else {

          //console.log('SUCCESS FALSE')
          selpage.errorHandler(jqXHR, statusText);

        }

      },
      error: function(jqXHR, statusText) {
        //console.log('ERROR')
        selpage.errorHandler(jqXHR, statusText);
      }
    })

  },


  isNumber: function(n) {
    return n == parseFloat(n);
  },

  isEven: function(n) {
    return selpage.isNumber(n) && (Math.abs(n) % 2 == 1);
  },

  epochtoprettydate: function (epoch, totalchart, prependyear) {

    var dt = new Date(epoch * 1000)

    // FOR ADE - NOT SURE WHY THIS IS NEEDED?
    if (isNaN(dt.getTime())) {
      dt = new Date();
    }

    var days = [ls.psMessage89, ls.psMessage90, ls.psMessage91, ls.psMessage92, ls.psMessage93, ls.psMessage94, ls.psMessage95];
    var months = [ls.psMessage96, ls.psMessage97, ls.psMessage98, ls.psMessage99, ls.psMessage100, ls.psMessage101, ls.psMessage102, ls.psMessage103, ls.psMessage104, ls.psMessage105, ls.psMessage106, ls.psMessage107];
    var year = dt.getFullYear();
    var day = days[dt.getDay()].toString();
    var month = months[dt.getUTCMonth()].toString();
    var date = dt.getUTCDate().toString();
    var hour = dt.getUTCHours().toString()
    var min = dt.getUTCMinutes().toString()

    if (min < 10) {
      min = '0' + min;
    }

    var datestring = month + ' ' + date + ' ' + year + ', ' + hour + ':' + min;

    return datestring

  },


  returnPagingBar: function (options) {

    /*
    var options = {
    id: 'table_id_goes_here',
    totalcount: 165,
    currentpage: 1,
    pagesize: 50
    }
    */

    var id = options && options.id ? options.id : false;
    var totalcount = options && options.totalcount ? options.totalcount : selpage.totalcount;
    var currentpage = selpage.currentpage ? Number(selpage.currentpage) : 1;
    var pagesize = options && options.pagesize ? options.pagesize : 20;
    var totalpages = Math.ceil(totalcount / pagesize);

    var startPageNumber;
    var endPageNumber;

    // ADRIAN CODE
    // WORK OUT HOW MANY PAGES ARE REQUIRED
    if ((currentpage - 3) < 1) {
      startPageNumber = 1
    } else {
      startPageNumber = currentpage - 2
    }

    if (totalpages < (currentpage + 2)) {
      endPageNumber = totalpages
    } else {
      endPageNumber = currentpage + 2
    }

    if (endPageNumber - startPageNumber > 4) {

    } else {

      if (currentpage == 1) {
        if (totalpages < 5) {
          endPageNumber = totalpages
        } else {
          endPageNumber = endPageNumber + 2
        }
      } else {
        if (totalpages == 2) {
          if (endPageNumber < 5) {
            endPageNumber = totalpages
          } else {
            endPageNumber = endPageNumber + 1
          }
        }
      }

      if (currentpage == totalpages) {
        if (totalpages > 5) {
          startPageNumber = totalpages - 4
        } else {
          startPageNumber = 1
        }
      } else {
        if (currentpage == (totalpages - 1)) {
          if (totalpages > 5) {
            startPageNumber = totalpages - 4
          } else {
            startPageNumber = 1
          }
        }
      }

    }

    // END ADRIAN CODE

    var pages = '';
    for (let i = startPageNumber; i < endPageNumber + 1; i++) {
      var selected = i == currentpage ? ' class="selected"' : '';
      pages += '<li class="visible-md"><a href="#page' + i + '" ' + selected + ' page="' + i + '">' + i + '</a></li>'
    }

    var first = currentpage != 1 ? '<li><a href="#first" class="pagefirst" page="1" title="' + ls.text_firstpage + '">' + ls.text_firstpage + '</a></li>' : '';
    var prev = currentpage != startPageNumber ? '<li><a href="#prev" class="pageprev" page="' + (currentpage - 1) + '" title="' + ls.text_prevpage + '">' + ls.text_prevpage + '</a></li>' : '';
    var next = currentpage != endPageNumber ? '<li><a href="#next" class="pagenext" page="' + (currentpage + 1) + '" title="' + ls.text_nextpage + '">' + ls.text_nextpage + '</a></li>' : '';
    var last = currentpage != totalpages ? '<li><a href="#last" class="pagelast" page="' + totalpages + '" title="' + ls.text_lastpage + '">' + ls.text_lastpage + '</a></li>' : '';

    var html = '<div class="wrjs_paging paging">' +
        '<ul>' +
        first +
        prev +
        pages +
        next +
        last +
        '<li class="pageinfo right">' + ls.text_page + ' ' + currentpage + ' / ' + totalpages + '</li>' +
        //'<li class="right"><a href="#reload" class="pagereload" page="' + currentpage + '" title="' + text_reloaddata + '">' + text_reloaddata + '</a></li>' +
        '</ul>' +
        '</div>';

    return html

  },


  currentpage: 1,

  // REMOVE DUPLICATES FROM AN ARRAY
  removeDuplicates: function(arr) {

    var temp = {};
    for (var i = 0; i < arr.length; i++)
      temp[arr[i]] = true;
    var r = [];
    for (var k in temp)
      r.push(k);
    return r;

    //return arr.slice().sort(function(a,b){return a > b}).reduce(function(a,b){if (a.slice(-1)[0] !== b) a.push(b);return a;},[]);

  },

  buildConsoleTable: function(json) {

    //console.log('buildConsoleTable', json)

    var rows = '';

    $.each(json, function(i, row) {

      const isDeactivated = row.deactivated === 'Y';
      const isExpired = row.expired === 'Y';
      const isSuspended = row.suspended === 'Y';
      const hideLink = isDeactivated || isExpired || isSuspended;

      var keycount = row.accesslevel > 1 ? row.keycount  : '-';
      var devices = row.accesslevel > 1 ? row.devicesallowed : '-';
      var expired = row.accesslevel > 1 ? ' <em class="light">(' + row.expiredkeys + ' ' + ls.text_expired + ')</em>' : '';
      var showkeys = row.accesslevel > 1 ? ' <a href="#nogo" class="showkeys" marid="' + row.marid + '">' + ls.text_view + '</a>' : '';

      var d = new Date(row.datecreated + 'Z');
      var epoch = d.getTime()/1000;
      var datecreated = selpage.epochtoprettydate(epoch);

      //var billingtypes = selpage.removeDuplicates(row.confautobilling.split(','));
      var billingtypes = row.confautobilling.split(',');
      var utility = $.inArray('utility', billingtypes) > -1;

      // IF MIXED BAG, ADD A TOOLTIP TO EXPLAIN THIS
      // IF UTILITY ONLY, DISPLAY UNLIMITED IN THE DEVICES PURCHASED COLUMN
      var tooltip = '';
      if(utility && row.accesslevel > 1) {
        if(billingtypes.length == 1) {
          // UTILITY ONLY
          devices = ls.text_unlimited;
        } else {
          // MIXED BAG
          tooltip = '<span class="tooltip_icon grey" tooltip="' + ls.text_mixedbag  + '">&nbsp;</span>';
        }
      }

      var name = row.marname.length > 0 ? row.marname : ls.text_unnamedconsole;

      const link = `<a href="selection.aspx?S=Y&MAR=${row.marid}" class="console" title="${ls.text_css_accessthisconsole}" mar="${row.marid}">${name}</a>`;

      const nameLink = hideLink ? name : link;

      rows += '<tr>' +
          '<td class="cell_0">' + nameLink + '</td>' +
          '<td class="cell_2  hidden-xs">' + keycount + expired + showkeys + '</td>' +
          '<td class="cell_3  hidden-xs">' + devices + tooltip + '</td>' +
          '</tr>';

    });

    //var paging = selpage.totalcount > 20 ? selpage.getPagingBar() : '';
    var paging = selpage.totalcount > 20 ? selpage.returnPagingBar() : '';
    var header = selpage.gsmConsoles ? ls.text_businessconsumerconsoles : ls.text_consoles;

    var table = '<div id="grid_consoles" class="wrjs_grid green">' +

        '<h2 class="tableheader clear">' + header + '</h2>' +

        '<div class="left hundred">' +
        '<div class="dashinner">' +

        '<table id="consoletable" class="wrjs_table fixed selectiontable">' +

        '<thead>' +
        '<th class="cell_0">' + ls.text_css_consolename + '</th>' +
        //'<th class="cell_1  hidden-xs">' + ls.text_css_datecreated + '</th>' +
        '<th class="cell_2  hidden-xs">' + ls.text_css_keycodes + '</th>' +
        '<th class="cell_3  hidden-xs">' + ls.text_css_devicesallowed + '</th>' +
        //'<th class="cell_4  hidden-xs">' + ls.text_css_expiredkeycodes + '</th>' +
        '</thead>' +

        '<tbody>' +
        rows +
        '</tbody>' +

        '</table>' +

        '</div>' +
        '</div>' +

        paging +

        '</div>';

    var config = { ADD_ATTR: ['page', 'marid', 'mar']};
    // Clean HTML string and write into our DIV
    table = DOMPurify.sanitize(table, config);
    if(selpage.gsmConsoles) {
      table = '<div class="right fifty">' + table + '</div>';
    };

    // ALWAYS REMOVE PREVIOS INSTANCES
    $('#grid_consoles').remove();

    var ct = $('#grid_consoles');

    if(ct.length > 0) {

      if(selpage.gsmConsoles) {

        $('.paging').remove();

        $(table).insertBefore('.right.fifty');

        ct.parent().remove();

      } else {

        ct.parent().html(table);

      }

    } else {

      $('#contentarea .wrapper').append(table);

    }

  },

  buildGSMTable: function(json) {

    //console.log('buildGSMTable', json)

    $('#grid_gsm').remove();

    var rows = '';

    $.each(json, function(i, row) {

      var d = new Date(row.datecreated + 'Z');
      var epoch = d.getTime()/1000;
      var datecreated = selpage.epochtoprettydate(epoch);
      var name = row.marname.length > 0 ? row.marname : ls.text_unnamedconsole;

      rows += '<tr>' +
          '<td class="cell_0"><a href="selection.aspx?S=Y&MAR=' + row.marid + '" class="console" title="' + ls.text_css_accessthisconsole + '" mar="' + row.marid + '">' + name + '</a></td>' +
          '<td class="cell_2 hidden-xs">' + row.childcount + ' <em class="light">(' + row.totalcount + ' ' + ls.text_sb_accessible + ', ' + row.deactivatedcount + ' ' + ls.psMessage28 + ')</em></td>' +
          '</tr>';
    })

    var table =     '<div id="grid_gsm" class="wrjs_grid blue">' +

        '<h2 class="tableheader clear">' + ls.text_gsmconsoles + '</h2>' +

        '<div class="left hundred">' +
        '<div class="dashinner">' +

        '<table id="gsmtable" class="wrjs_table fixed selectiontable">' +

        '<thead>' +
        '<th class="cell_0">' + ls.text_css_consolename + '</th>' +
        // '<th class="cell_1 hidden-xs">' + ls.text_css_datecreated + '</th>' +
        '<th class="cell_2 hidden-xs">' + ls.text_css_totalsites + '</th>' +
        '</thead>' +

        '<tbody>' +
        rows +
        '</tbody>' +

        '</table>' +
        '</div>' +
        '</div>' +

        '</div>';
    var config = { ADD_ATTR: ['page', 'mar']};
    // Clean HTML string and write into our DIV
    table = DOMPurify.sanitize(table, config);
    if(selpage.records && selpage.records.length > 0) {
      table = '<div class="left fifty">' + table + '</div>';
    };


    $('#contentarea .wrapper').prepend(table);


  }

};






var uber = uber || {};

uber.common = {

  init: function() {

    uber.common.checkCalcSupport();

    uber.common.createListeners();

    uber.common.checkMenuExists();

    uber.common.appendMobileMenu();

    uber.common.createCheckReplaceElements();


    // SET DEFAULT PARAMS FOR ALL AJAX CALLS
    if(typeof wrjs != 'undefined' && wrjs.Data) {
      $.extend(wrjs.Data.defaultParams, {whome: psCookieLogon});
    }

  },

  createListeners: function() {

    var page = $('body')[0].id;

    if(page != 'page_gsm' && page != 'page_default' && page != 'page_registration') {

      // TAB CLICK HANDLER
      $(document).on('click', '.tabs:not(.disabled) li a', function (e) {

        e.preventDefault();

        var hash = $(this).attr('href');
        var parentli = $(this).parent();
        var parentul = parentli.parent();

        // IF NOT WIZARD / STEPS
        if (!parentul.hasClass('disabled')) {
          if (!parentli.hasClass('selected')) {
            var lis = parentul.find('> li');
            $.each(lis, function (i, li) {
              var ids = $(li).find('a').attr('href');
              $(ids).hide()
            })
            lis.removeClass('selected');
            parentli.addClass('selected');
            $(hash).show();
          }
        }

        return false

      });


    }


    // DEAFULT ONLY LISTENERS
    if(page == 'page_default' || page == 'page_forgotpassword') {

      $('#Email').keyup(function() {
        $('#SecondaryAuth').val('');
      });

      $('#SecondaryAuth').keyup(function() {
        $('#Email').val('');
      });

    }

  },


  appendMobileMenu: function() {

    var page = $('body')[0].id;

    if(page == 'page_forgotpassword' || page == 'page_registration' || page == 'page_regconfirm') {
      var wrap = $('#accountwrapper');
      if(wrap.length > 0) {
        var str = '<ul id="accountnav"><li id="account"></li></ul>';
        $('#accountwrapper').append(str)
      } else {
        setTimeout(function() {
          uber.common.appendMobileMenu()
        },100)
      }
    }

  },
  
  checkMenuExists: function() {

    var wrapper = $('.global-nav-header');

    //console.log('checkMenuExists');

    if(wrapper.length > 0) {

      // THIS FUNCTION PUTS THE LANGUAGE HOLDER DIV IN THE CORRECT PLACE
      uber.common.showHiddenDivs();
      
      uber.common.measureMenu();
      

    } else {

      //console.log('WRAPPER NOT HERE YET');
      setTimeout(function() {
        uber.common.checkMenuExists();
      },100)
    }

  },
  
  showHiddenDivs: function() {
    
    var exists = $('#innerwrapper').length > 0;
    if(exists) {
      $('.global-nav-header').show();
      $('#innerwrapper').show();
    } else {
      setTimeout(function() {
        uber.showHiddenDivs();
      },100)
    }
    
  },

  getViewport: function() {

    var e = window, a = 'inner';

    if (!('innerWidth' in window )) {
      a = 'client';
      e = document.documentElement || document.body;
    }

    return { width : e[ a+'Width' ] , height : e[ a+'Height' ] };

  },

  attempts: 0,

  measureMenu: function() {

    //console.log('MEASURE MENU');

    // UBER ONLY!!!
    var page = $('body')[0].id;
    if(page != 'page_gsm') {

      //console.log('MEASURE');

      var cm = $('.clickme');
      var nav = $('#nav');

      if(nav.length > 0) {

        var anav = $('#accountnav');
        $('ul', anav).addClass('accountul');

        var body = $('body');
        body.removeClass('collapsemenu')

        var accul = $('.accountul');
        var acc = $('#account');
        var bodyw = uber.common.getViewport().width;
        var navw = nav.outerWidth();
        var cmw = cm.outerWidth();

        var liw = 0;

        // KEEP THIS VALUE UP TO DATE WITH THE CSS MEDIA QUERIES INSIDE MOBILE.CSS
        if(bodyw <= 600) {

          // IF LESS THAN 600 PIXELS WIDE, MOVE ACCOUNT DROPDOWN MENU UNDER THE NAVWRAPPER DIV
          // NAVWRAPPER BECOMES THE SLIDEOUT MENU
          if(accul.closest('#navwrapper').length == 0) {
            accul.insertAfter('#navwrapper #nav');
          }

          //$('#languageholder').insertBefore('#footer').show();

        } else {

          // IF MORE THAN 600 PIXELS
          // MOVE ACCOUNT UL, BACK INSIDE THE ACCOUNT LI
          if(!accul.next().hasClass('header')) {
            accul.appendTo('#accountnav #account');
            acc.removeClass('clicked')
            $('body').removeClass('menuswoosher');
          }

          //$('#languageholder').prependTo('#accountwrapper').show();

        }

        $('li', nav).each(function(i, n) {
          var nw = $(n).outerWidth();
          liw = liw + nw;
        })

        if(cmw + liw > navw) {
          //console.log(1);
          body.addClass('collapsemenu');
        }

      } else {

        // TWO AND A HALF SECONDS
        if(uber.common.attempts < 25) {
          //console.log('AGAIN', attempts);
          setTimeout(function() {
            uber.common.measureMenu();
          },100)
          uber.common.attempts ++
        }

      }

    }

  },


  checkCalcSupport: function() {

    /***********************************************************/
    // CSS3 CALC SUPPORT
    /***********************************************************/

    $('body').append('<div id="css3-calc"></div>');
    if ($('#css3-calc').width() == 20) {
      $('body').addClass('calc_true');
    }
    $('#css3-calc').remove(); // Remove the test element

  },

  createCheckReplaceElements: function() {

    // PUT CHECK REPLACE ELEMENTS AFTER ALL RADIOS AND CHECKBOXES IF THEY DON'T ALREADY EXIST
    var checkboxesAndRadios = $('input[type="checkbox"], input[type="radio"]')
    $.each(checkboxesAndRadios, function(i, el) {

      var t = $(el);

      var n = t.next();
      if(!n.hasClass('checkreplace')) {
        //console.log('ADD CHECKREPLACE DIV', t, n);
        t.after('<div class="checkreplace"></div>');
      }

      var parent = t.parent();
      if(parent[0].nodeName != 'LABEL') {
        //console.log('WRAP CHECKREPLACE LABEL', t, n, parent);
        t.next('.checkreplace').andSelf().wrapAll('<label />');
      }

    })

  },


  termsPresentOnGSM: function() {

    var present = false;
    var agreementpop = $('.agreementpop');
    if(psAgreeTermsConditions == 'True' && agreementpop.length > 0) {
      present = true;
    };
    return present;

  },


  centerElement: function (els) {

    if (els.length > 0) {

      $.each(els, function (i, el) {

        var el = $(el);
        var vis = el.is(':visible');
        var w = el.outerWidth();
        var h = el[0].scrollHeight;

        // SHOW TO MEASURE, THEN HIDE AGAIN
        if(!vis) {
          el.css('left', '-999em').show();
          w = el.outerWidth();
          h = el[0].scrollHeight;
          el.css('left', '50%').hide();
        }

        var winheight = $(window).height();
        var pos = el.css('position');

        if (winheight < h) {

          el.css({
            marginLeft: -w / 2,
            marginTop: 0,
            top: 0
          })

          if (pos == 'fixed') {
            el.css({
              height: winheight,
              overflow: 'auto'
            })
          }

        } else {
          el.css({
            marginLeft: -w / 2,
            marginTop: -h / 2,
            top: '50%',
            height: 'auto'
          })
        }

      })

    }

  },

  showactivetabs: function(config) {

    var triggerclick = config && config.triggerclick || false;
    var parent = config && config.parentid ? config.parentid : false;
    var tabcontent = parent.length == 0 ? $('.tabcontent') : $('.tabcontent', parent);
    var tabsul = parent.length == 0 ? $('.tabs') : $('.tabs', parent);

    //console.log('SHOWACTIVETABS', parent)

    $.each(tabsul, function(i, tabli) {

      var activelia = $('li.selected a', tabli);

      if(activelia.length == 0) {

        //console.log('NO SELECTED LI - THEREFORE SELECT FIRST LI')

        var firstlia = $('li:nth-child(1) a', tabli);
        var hash = firstlia[0].hash;

        // HIDE ALL TABCONTENTS
        tabcontent = tabcontent.not(hash);
        tabcontent.hide();

        if(triggerclick) {
          firstlia.trigger('click');
        } else {
          $(hash).show();
          firstlia.parent().addClass('selected');
        }

      } else {

        //console.log('AN LI IS SELECTED')

        var hash = activelia[0].hash;
        var activecontent = $(hash);

        // HIDE ALL TABCONTENTS
        tabcontent = tabcontent.not(hash);
        tabcontent.hide();

        if(triggerclick) {
          activelia.removeClass('selected').trigger('click');
        } else {
          // RE-SHOW THIS CONTENT
          activecontent.show();
        }

      }

    })


  },

};


// **********************************************************************************************
// UBER FORM LOGIC
// **********************************************************************************************


uber.form = (function () {


  // **********************************************************************************************
  // SETUP
  // **********************************************************************************************

  var _init = function() {

    _createListeners();

  };

  var _createListeners = function() {

    $('body').on('click', 'a#addlicense:not(.disabled)', function(e) {
      _handleFormSubmit(this, e);
    });

    $('body').on('change', 'input#checkbox_terms, #Region', function(e) {
      _enableDisableSubmitButton(this)
    });

  };

  // **********************************************************************************************
  // PRIVATE
  // **********************************************************************************************

  var _enableDisableSubmitButton = function(el) {

    var t = $(el);

    var length ;
    if(el.id == 'Region') {
      length = t.val().length > 0;
    } else {
      length = t.is(':checked');
    }

    if(length) {
      $('#addlicense').removeClass('disabled');
    } else {
      $('#addlicense').addClass('disabled');
    }

  };

  var _sanitizeKeycode = function() {

    // SANITIZE KEYCODE AND RETURN RESULT OF LENGTH CHECK

    var keycode = $('#KeyCode')
    var val = keycode.val();
    val = val.replace(/\s/g, '');
    keycode.addClass('disabled').prop('readonly', true).val(val);

    var correctLength = val.length == 20 || val.length == 24 || val.length == 36;

    return correctLength

  };

  var _resetFormErrors = function() {

    // TIDY UP
    $('#errormessage').remove();
    $('p.fielderror,p.error').remove();
    $('input.fielderror,input.error').removeClass('fielderror,error');

  };


  var _handleKeycodeInvalid = function() {

    var keycode = $('#KeyCode');

    keycode.prop('readonly', false).removeClass('disabled').closest('label').addClass('fielderror');
    keycode.after('<p class="error">' + ls.psMessage24 + '</p>');

    $('#ent').val('0');
    $('#reg').val('0');
    $('#dterms').hide();
    $('#dregion').hide();
    $('#pterms').hide();
    $('#pregion').hide();

    $('#addlicense').removeClass('disabled');

  };


  var _checkKeycode = function() {

    $('#form_licenseadd .inner').append('<p id="checkingkeycode">' + ls.text_checkingKeycode + '</p>');

    var key = $('#KeyCode').val();

    $.ajax({
      url: psURLPrefix + psGoSite + 'my.webrootanywhere.com/aboutkey.aspx?K=' + key + '&version=' + Date.now(),
      type: 'GET',
      success: function (data) {
        _handleKeycodeCheckResult(data)
      }
    });

  };

  var _handleKeycodeCheckResult = function(data) {

    $('#checkingkeycode').remove();

    if(data == 'Error') {
      _handleKeycodeInvalid();
    } else {
      _handleKeycodeValid(data);
    }

  };


  var _handleKeycodeValid = function(data) {

    var result = data.split(",");
    var enterprise = result[0] == '1';
    var backupsync = result[1] == '1';
    var bestbuy = result[2] == '1';

    $('#pterms').hide();
    $('#pregion').hide();

    if(enterprise || backupsync || bestbuy) {

      if(enterprise) {

        _handleEnterpriseKey();

      } else {

        _handleNonEnterpriseKey(backupsync, bestbuy);

      }

    } else {

      // SUBMIT FORM
      _submitForm()

    }

  };


  var _handleEnterpriseKey = function() {

    var newconsole = $('#NewConsoleYes').is(':checked');

    if(!newconsole && psAgreeTermsConditions == 'False' && psConsoleType == 'E') {

      // IF THEY DONT WANT A NEW CONSOLE
      // THE CURRENT CONSOLE IS A BUSINESS CONSOLE
      // AND THEY HAVE AGREED TO THE CURRENT TERMS
      _submitForm();

    } else {

      // SET BUSINESS FIELD TO TRUE
      // SHOW ACCEPT TERMS PARAGRAPH
      $('#ent').val('1');
      $('#dterms').show();

    }

  };

  var _handleNonEnterpriseKey = function(backupsync, bestbuy) {

    var newconsole = $('#NewConsoleYes').is(':checked');

    // IF KEY IS BESTBUY, BUT CONSOLE IS NON_BESTBUY
    // OR IF KEY IS NOT BESTBUY, BUT CONSOLE IS BESTBUY
    // OR NEW CONSOLE REQUESTED
    // SET REGION TO ZERO
    if((bestbuy && psBBY == '0') || (!bestbuy && psBBY != '0') || newconsole) {
      psRegionID = 0; // SET CONSOLE REGION VALUE TO "NOT SET"
    };

    // IF BACKUP AND SYNC KEY, AND REGION NOT SET FOR CONSOLE
    // SHOW REGION SELECT
    if (backupsync == 1 && psRegionID == 0) {
      $('#reg').val('1'); // SET REG AS REQUIRED
      $('#Region').val('');
      $('#dregion').show();
    };

    // CONSOLE REGION IS ALREADY SET
    // PASS THE CURRENT REGION VALUE
    if (psRegionID != 0) {
      $('#reg').val('1'); // SET REG AS REQUIRED
      $('#Region').val(psRegionID);
      _submitForm();
    }

    // NO BACKUP ON KEYCODE
    // BESTBUY ON KEY
    if (!backupsync && bestbuy) {
      _submitForm();
    };

  };


  var _submitForm = function() {

    $('#form_licenseadd').submit();

  };


  var _handleFormSubmit = function(el, e) {

    var t = $(el);
    t.addClass('disabled');

    // TIDY UP
    _resetFormErrors()

    // SANITIZE KEYCODE AND RETURN RESULT OF LENGTH CHECK
    var keyValid = _sanitizeKeycode();
    if(!keyValid) {

      // KEYCODE INVALID - SHOW ERROR
      _handleKeycodeInvalid()

    } else {

      // KEYCODE IS VALID - PROCEED
      if($('#ent').val() == '1' || $('#reg').val() == '1') {
        _submitForm();
      } else {
        _checkKeycode();
      }

    }

  };


  // **********************************************************************************************
  // PUBLIC
  // **********************************************************************************************

  return {
    init: _init
  };


})();




// **********************************************************************************************
// DOC READY
// **********************************************************************************************

$(document).ready(function() {

  // WAIT FOR EVERYTHING TO HAVE BEEN LOADED
  uber.common.init();

  uber.form.init();

})


export default window.uber = uber;