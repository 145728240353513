import XRegExp from 'xregexp';

String.prototype.replaceAll = function (find, replace) {
    var str = this;
    return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};


/***********************************************************/
// CARET POSITION

/*
$("#id").caret(); //get the begin/end caret position
$("#id").caret().begin;
$("#id").caret().end;
$("#otherId").caret(5); //set the caret position by index
$("#otherId").caret(1, 5); //select a range
*/

/***********************************************************/

(function ($) {

	$.fn.caret = function (begin, end) {

	    if (this.length == 0) return;

	    if (typeof begin == 'number') {

	        end = (typeof end == 'number') ? end : begin;
	        return this.each(function ()
	        {
	            if (this.setSelectionRange)
	            {
	                this.setSelectionRange(begin, end);
	            } else if (this.createTextRange)
	            {
	                var range = this.createTextRange();
	                range.collapse(true);
	                range.moveEnd('character', end);
	                range.moveStart('character', begin);
	                try { range.select(); } catch (ex) { }
	            }
	        });

	    } else {

	        if (this[0].setSelectionRange) {
	            begin = this[0].selectionStart;
	            end = this[0].selectionEnd;
	        } else if (document.selection && document.selection.createRange) {
	            var range = document.selection.createRange();
	            begin = 0 - range.duplicate().moveStart('character', -100000);
	            end = begin + range.text.length;
	        }
	        return { begin: begin, end: end };

	    }

	}

})(jQuery);

/***********************************************************/
// MASK
/***********************************************************/

(function ($) {

	$.fn.mask = function (label, cls, delay) {

		$(this).each(function () {
			if (delay !== undefined && delay > 0) {
				var element = $(this);
				element.data("_mask_timeout", setTimeout(function () { $.maskElement(element, label) }, delay));
			} else {
				$.maskElement($(this), label, cls);
			}
		});
	};

	$.fn.unmask = function () {
		$(this).each(function () {
			$.unmaskElement($(this));
		});
	};

	$.fn.isMasked = function () {
		return this.hasClass("masked");
	};

	$.maskElement = function (element, label, cls) {
		
		// MERU CHANGE - NEVER MASK THE BODY, INSTEAD THE UBERCONTENT DIV
		var isBody = element == 'body' || (element[0] && element[0].nodeName && element[0].nodeName == 'BODY');
		if(isBody) {
			element = $('#ubercontent');
		}

		var cls = cls || '';

		if(element.data("_mask_timeout") !== undefined) {
			clearTimeout(element.data("_mask_timeout"));
			element.removeData("_mask_timeout");
		}

		if(element.isMasked()) {
			$.unmaskElement(element);
		}

		if(element.css("position") == "static") {
			element.addClass("masked-relative");
		}

		element.addClass("masked");

		var maskpos = 'absolute !Important;';

		var maskDiv = $('<div class="loadmask ' + cls + '" style="position:' + maskpos + ';"></div>');

		if (navigator.userAgent.toLowerCase().indexOf("msie") > -1) {
			maskDiv.height(element.outerHeight());
			maskDiv.width(element.outerWidth());
		}

		if (navigator.userAgent.toLowerCase().indexOf("msie 6") > -1) {
			element.find("select").addClass("masked-hidden");
		}

		element.append(maskDiv);
		maskDiv.velocity({opacity: 0.5});

		if (label !== undefined && label !== false) {
			var maskMsgDiv = $('<div class="loadmask-msg ' + cls + '" style="display:none; position:' + maskpos + '"></div>');
			maskMsgDiv.append('<div class="loadmask-spinner"></div> ' + label);
			element.append(maskMsgDiv);

			var w = maskMsgDiv.outerWidth() / 2;
			var h = maskMsgDiv.outerHeight() / 2;
			var windowHeight = $(window).height();
			maskMsgDiv.css({
				left: '50%',
				top: '50%',
				marginTop: '-' + h + 'px',
				marginLeft: '-' + w + 'px'
			})

			// If the maskDiv is inside the viewport but overflowing
			if (maskDiv.offset().top < windowHeight && (maskDiv.offset().top + maskDiv.outerHeight()) > windowHeight) {
				maskMsgDiv.css({
					top: (windowHeight - maskDiv.offset().top) / 2 + 'px'
				})
			}

			maskMsgDiv.velocity('fadeIn', {duration: 250});

		}

	};

	$.unmaskElement = function (element) {

		if (element.data("_mask_timeout") !== undefined) {
			clearTimeout(element.data("_mask_timeout"));
			element.removeData("_mask_timeout");
		}

		element.find(".loadmask-msg,.loadmask").remove();
		element.removeClass("masked");
		element.removeClass("masked-relative");
		element.find("select").removeClass("masked-hidden");

	};

})(jQuery);




// ***********************************************************
// SETUP
// ***********************************************************

wrjs = wrjs || {};

wrjs.utils = wrjs.utils || {};

// ***********************************************************
// COMMON UTILITIES
// ***********************************************************

(function () {

	// ***********************************************************
	// PRIVATE!!!
	// ***********************************************************

	var _encodeSetupComplete = false;
	var _charToEntity = {};
	var _entityToChar = {};
	var _entityToCharRegex;
	var _charToEntityRegex;

	var _encodeSetup = function() {
		if(!_encodeSetupComplete) {
			_addCharacterEntities({
				'&amp;': '&',
				'&gt;': '>',
				'&lt;': '<',
				'&quot;': '"',
				'&#39;': "'"
			});
		}
	};

	var _addCharacterEntities = function(newEntities) {

		var charKeys = [];
		var entityKeys = [];
	    var key;
		var echar;

		for(key in newEntities) {
			echar = newEntities[key];
			_entityToChar[key] = echar;
			_charToEntity[echar] = key;
			charKeys.push(echar);
			entityKeys.push(key);
		}

		_entityToCharRegex = new RegExp('(' + entityKeys.join('|') + '|&#[0-9]{1,5};' + ')', 'g');
		_charToEntityRegex = new RegExp('(' + charKeys.join('|') + ')', 'g');

	};

	var _htmlEncodeReplaceFn = function (match, capture) {
		return _charToEntity[capture];
	};

	var _htmlDecodeReplaceFn = function (match, capture) {
		return (capture in _entityToChar) ? _entityToChar[capture] : String.fromCharCode(parseInt(capture.substr(2), 10));
	};


	var _executeFunctionByName = function(func, extra) {

		var context = window,
			functionName = func,
			namespaces = functionName.split("."),
			func = namespaces.pop();

		for (var i = 0; i < namespaces.length; i++) {
			context = context[namespaces[i]];
		}

		let t = extra || context;

		return context[func].apply(t, [extra]);

	};

	var _ip2int = function(dot) {
	    var d = dot.split('.');
	    return ((((((+d[0])*256)+(+d[1]))*256)+(+d[2]))*256)+(+d[3]);
	};

	var _int2ip = function(num) {
	    var d = num%256;
	    for (var i = 3; i > 0; i--)
	    {
	        num = Math.floor(num/256);
	        d = num%256 + '.' + d;
	    }
	    return d;
	};


	var _numberWithCommas = function(num) {

		if (num < 100000) {
			num = Math.round(num * 10) / 10;
		} else {
			num = Math.round(num);
		}
		var num_str = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return num_str;

	};


	var _querystringToJSON = function (string) {

		if(string && typeof string == 'string') {

			var pairs = string.split('&');
			var result = {};
			$.each(pairs, function (i, pair) {
				pair = pair.split('=');
				result[pair[0]] = decodeURIComponent(pair[1] || '');
			});
			return JSON.parse(JSON.stringify(result));

		} else {

			return {};

		}

	};

	var _JSONToQuerystring = function (json) {
		return decodeURIComponent($.param(json));
	};

	var _abbreviateNumber = function(num) {

		function intlFormat(num) {
  		return new Intl.NumberFormat().format(Math.round(num*10)/10);
		};

		if(typeof Intl != 'undefined') {

			if(num >= 1000000000) {
				return intlFormat(num/1000000000)+'B';
			};

			if(num >= 1000000) {
				return intlFormat(num/1000000)+'M';
			};

			if(num >= 1000) {
				return intlFormat(num/1000)+'k';
			};

			return intlFormat(num);

		} else {

			return num

		};

	};

	var _validDomainRegex = function(tld) {
		// WRGC-6924 - SAME REG EX AS SITES DNS DOMAIN BYPASS LIST
		// const invalidChars = '\\s!@#$%^&*()~£+_\\=\\[\\]{};`\':"\\|,.<>\\\\/?';
		// const validChars = '\\p{L}\\p{N}'; // unicode characters and numbers
		//
		// return XRegExp(`^((\\*\\.)*)?(([^-${invalidChars}][${validChars}-_]*)[^-\\.${invalidChars}]+\\.)*([^-\\.${invalidChars}][${validChars}-]*){1}[^-\\.${invalidChars}]+\\.[\\p{L}\\p{N}]{2,}$`);

		const invalidChars = `\\s!@#$%^&*()~£+_\\=\\[\\]{};\`':"\\|,.<>\\\\/?`;
		const validChars = `\\p{L}\\p{N}`; // unicode characters and numbers
		if (tld){
			// tests the tld as well
			return XRegExp(`^((\\*\\.)*)?(([^-${invalidChars}][${validChars}-_]*)[^-\\.${invalidChars}]+\\.)*([^-\\.${invalidChars}][${validChars}-]*){1}[^-\\.${invalidChars}]+\\.[\\p{L}\\p{N}]{2,}$`);
		}
		return XRegExp(`^((\\*\\.)*)?(([^-${invalidChars}][${validChars}-_]*)[^-\\.${invalidChars}]+\\.)*([^-\\.${invalidChars}][${validChars}-]*){1}[^-\\.${invalidChars}]$`);
	}

	// ***********************************************************
	// PUBLIC
	// ***********************************************************

	var publicFunctions = {

		htmlEncode: function(value) {
			return (!value) ? value : String(value).replace(_charToEntityRegex, _htmlEncodeReplaceFn);
		},

		htmlDecode: function(value) {
			return (!value) ? value : String(value).replace(_entityToCharRegex, _htmlDecodeReplaceFn);
		},

		replaceAll: function (string, find, replace) {
			return string.replace(new RegExp(cjw.global.escapeRegExp(find), 'g'), replace);
		},

		slugify: function (string) {
			return string.toString().toLowerCase()
				.replace(/\s+/g, '-')
				.replace(/[^\w\-]+/g, '')
				.replace(/\-\-+/g, '-')
				.replace(/^-+/, '')
				.replace(/-+$/, '');
		},

		executeFunctionByName: _executeFunctionByName,

		ip2int: _ip2int,
		int2ip: _int2ip,

		numberWithCommas: _numberWithCommas,

		abbreviateNumber: _abbreviateNumber,

		querystringToJSON: _querystringToJSON,

		JSONToQuerystring: _JSONToQuerystring,

		validDomainRegex: _validDomainRegex

	};

	// ***********************************************************
	// INIT
	// ***********************************************************

	_encodeSetup();

	$.extend(wrjs.utils, publicFunctions)

})();
