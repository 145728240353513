
// **********************************************************************************
// WRJS DATA CONTROLS
// **********************************************************************************
import moment from 'moment';
const offset = window.psHr * 60 + window.psMin;
const transformDate = function(date, transform){
  if(transform){
    // We have to transform the current time to the timezone of the admin setting
    return moment(new Date(date)).utcOffset(offset).format('YYYY-MM-DD HH:mm:ss');
  }
  // Otherwise
  return moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss');
};

wrjs.Data = {

  defaultParams: {
    whome: psCookieLogon
  },

  createStoreWithStoreConfig: function(_config){

    // CREATE STORE
    // IF STORE DOESN'T ALREADY EXIST
    // AND STORECONFIG IS PRESENT
    if(!wrjs.Data.storeManager[_config.storeId]){
      var storeConfig = _config.storeConfig;
      if(storeConfig){
        storeConfig.id = _config.storeId;
        var s = wrjs.Data.Store(storeConfig);
        if(_config.data){
          s.loadData(_config.data)
        }
      }
    }

  },


  errorHandler: function(response, textStatus, ajaxSettings){

    //console.log('ERRORHANDLER', response, textStatus, ajaxSettings, arguments);

    response = response || '';
    textStatus = textStatus || '';
    ajaxSettings = ajaxSettings || false;

    var statuscode = response.status || '';

    // IF ABORTED, SILENTLY FAIL
    if(textStatus != 'abort'){

      var rType = typeof response;
      var rText = response.responseText || false;

      // TRY CONVERTING STRING TO AN OBJECT
      // SOMETIMES THE OBJECT GETS PASSED INTO HERE STRINGIFIED
      if(rType == 'string'){
        try {
          response = JSON.parse(response);
          rType = typeof response;
        } catch(e){
          //console.log(e);
        }
      }

      if(rType == 'string'){

        // IF STRING IS RETURNED
        // IF HTML, SESSION HAS ENDED, LOGOFF
        // ELSE, THROW TO MESSAGE HANDLER

        if(statuscode == 200 && response.toLowerCase().indexOf('error:logout') != -1){

          //console.log(1, 'Logoff', response, textStatus, ajaxSettings);
          wrjs.Data.errorLogoff();

        } else {

          // HANDLE ANY OTHER ERROR MESSAGES
          //console.log(2, 'Error: ', response, textStatus, ajaxSettings);
          wrjs.Data.messageHandler(response);

        }

      } else {

        // IF OBJECT IS RETURNED

        // VARIOUS TIMEOUT SCENARIOS
        var ReTryError = response.message && response.message == 'ReTryError';
        var gatewayTimeout = response.statusText && response.statusText.toLowerCase().indexOf('timeout') > -1;
        if(textStatus == 'timeout' || ReTryError || gatewayTimeout){

          // IF TIMEOUT OF ANY KIND, THROW TO HANDLETIMEOUT FUNCTION
          //console.log(3, 'Timeout: ', response, textStatus, ajaxSettings);
          if(ajaxSettings){
            wrjs.Data.handleTimeout(ajaxSettings);
          }

        } else {

          if(statuscode == 200 && rText && rText.toLowerCase().indexOf('error:logout') != -1){

            //console.log(4, 'Logoff', response, textStatus, ajaxSettings);
            wrjs.Data.errorLogoff();

          } else {

            // HANDLE ANY OTHER ERROR MESSAGES
            //console.log(5, 'Error: ', response, textStatus, ajaxSettings);
            wrjs.Data.messageHandler(response)

          }

        }

      }

    } else {

      //console.log(6, 'ABORT', response, textStatus, ajaxSettings);

    }
    

  },

  errorLogoff: function(){
    
    //console.log('errorLogoff')
  
    wrjs.View.removeAllModals();
    
    var logoffURL = msp.global.uberurl + '/logoff.aspx?J=Y';

    var options = {
      id: 'errorlogoff',
      title: window.ls.text_expiredtitle,
      body: '<p>' + window.ls.text_expiredcontent + '</p>',
      confirmtext: window.ls.text_ok,
      width: 400,
      canceltext: false,
      preRenderer: function() {
        $('#wrjs_modal_errorlogoff .button').attr('href', logoffURL);
        $('#wrjs_modal_errorlogoff .cancelclick').remove();
        
        /*
        setTimeout(function(){
          location.reload(true);
        },1000)
        */
        
      },
      confirmCallback: function(){
        window.location = logoffURL;
      },
      cancelCallback: function(){
        window.location = logoffURL;
      }
    };
    
    // IF JS FAILS, JUST THROW OUT
    if(wrjs && wrjs.View && wrjs.View.Modal){
      wrjs.View.Modal(options);
    } else {
      window.location = logoffURL;
    }

  },

  handleTimeout: function(settings){

    //console.log('handleTimeout', settings)

    var ajaxErrors = wrjs.Data.ajaxErrors;

    var cmd = settings.url.toLowerCase().split('cmd=')[1].split('&')[0];

    if(!ajaxErrors[cmd]){
      ajaxErrors[cmd] = { count: 0 };
    }

    ajaxErrors[cmd].count++

    //console.log('RETRY ' + ajaxErrors[cmd].count + ' of ' + ajaxErrors.retries, ajaxErrors[cmd])

    if(ajaxErrors[cmd].count <= ajaxErrors.retries){

      //$.ajax(settings);
      wrjs.Data.makeAjaxCall(settings);

    } else {

      wrjs.Data.messageHandler(
        { reload: true, message: '<p>' + window.ls.text_handletimeout1 + '</p><p>' + window.ls.text_handletimeout2 + '</p>' })

    }

  },

  messageHandler: function(response){

    console.log('messageHandler', response);
    
    // CHECK ERROR LOGOFF DOESNT EXIST
    if(wrjs.get('errorlogoff')) {
  
      console.log('Error logoff exists - exit')
  
    } else {
  
      console.log('Error logoff doesnt exist - continue')
  
      // PLEASE NOTE:
      // RELOAD TRUE WILL BE PASSED AS ONE OF THE RESPONSE PARAMS, IF TIMEOUT RETY HAS FAILED 3 TIMES
      // UNDER THIS SCENARIO, DISPLAY "CHECK INTERNET" MESSAGE
  
      var func;
      var confirmText = window.ls.text_ok;
      if (response.reload) {
    
        func = function () {
          $('.wrjs_modal').mask(window.ls.text_loading);
          setTimeout(function () {
            location.reload(true);
          }, 100)
        };
    
        confirmText = window.ls.text_reloadpage;
    
      } else {
    
        func = function () {
          // REMOVE ALL LOADING AND MASKING STATES!!!
          $('body').unmask();
          wrjs.View.removeAllModals();
        };
    
      }
  
      // RETURN THE CORRECT MESSAGE TO DISPLAY
      var msg = false;
      if (response.responseText) {
        if (typeof response.responseText == 'string') {
      
          msg = response.responseText;
      
          try {
            var rtext = JSON.parse(response.responseText);
            msg = rtext.message;
          } catch (e) {
            // DO NOTHING
          }
          ;
      
        } else {
      
          msg = response.responseText.message;
      
        }
        ;
    
      } else {
    
        msg = response.message || response;
    
      }
      
  
      if (!msg || typeof msg == 'undefined' || msg == 'ReTryError' || typeof msg == 'object' ||
        (typeof msg == 'string' && msg.toLowerCase().indexOf('doctype') > -1)) {
        msg = '<p>' + window.ls.text_genericerror + '</p><p>' + window.ls.text_genericerror2 + '</p>';
      }
      
  
      var options = {
        title: window.ls.text_error,
        body: '<p>' + msg + '</p>',
        confirmtext: confirmText,
        canceltext: false,
        confirmCallback: function () {
          func();
        },
        cancelCallback: function () {
          func();
        }
      };
  
      if ($('.wrjs_modal').length > 0) {
        var callback = function () {
          wrjs.View.Modal(options);
        };
        wrjs.View.removeAllModals(callback);
      } else {
        wrjs.View.Modal(options);
      }
      
  
    }

  },

  localData: {},

  localDataDelay: 2000,

  logLocal: false,

  returnLocalDataDelay: function(){
    return Number(wrjs.Data.localDataDelay);
  },

  getLocalData: function(url, params, success, failure){

    var records = [
      { id: '1', name: 'One' },
      { id: '2', name: 'Two' },
      { id: '3', name: 'Three' },
      { id: '4', name: 'Four' }
    ];

    if(params.cmd){

      // THIS CAN EITHER RETURN A DATA OBJECT OR A FUNCTION
      records = wrjs.Data.localData[params.cmd] || wrjs.Data.localData[params.cmd.toUpperCase()];

      // IF FUNCTION, CALL AGAIN PASSING PARAMS
      if(typeof records == 'function'){
        records = records(params)
      }
      ;

    }
    ;

    //console.log('getLocalData', url, params);

    var totalCount = records && records.length || 0;

    var response = { success: true, totalCount: totalCount, params: params, records: records };

    var delay = wrjs.Data.returnLocalDataDelay();
    if(delay != false){

      if(wrjs.Data.logLocal){
        //console.log('LOCAL CALL: ', params.cmd, params);
      }
      ;

      // DELAY RESPONSE

      setTimeout(function(){
        if(wrjs.Data.logLocal){
          //console.log('LOCAL RESPONSE: ', params.cmd, response, response.records)
        }
        ;
        success(response);
      }, delay)

    } else {

      success(response);

    }
    ;

  },

  xhrs: {},

  ajaxCount: 0,

  ajaxErrors: {
    retries: 1
  },

  convertParamsToContract: function(url, params){

    // DONT CONVERT THE CONTRACT VALUE UNLESS ITS A SKYGSM CALL
    // USER PREFERENCES NOW USES webapi.aspx SO THIS NEEDS TO BE EXCLUDED

    var userPrefs = url.toLowerCase().indexOf('userpreferences') > -1;

    if(!userPrefs) {

      var skygsm = url.indexOf('webapi.aspx') > -1;

      if(skygsm || params.includeContract){
        
        var bdapi = wrjs.Data.testIfBDAPIendpoint(params.cmd);
        
        if (bdapi) {
          
          // Calculate the offset
          params.offset = (Number(psHr) * 60) + Number(psMin);
          // Process the dates based on API (Command log uses utc based dates)
          let transform = false;
          if(params.cmd === '/api/CommandLog/ByEntity'){
            transform = true;
          }
          // Transform the start date
          if(params.startDate){
            params.startDate = transformDate(params.startDate, transform);
          }
          // Transform the end date
          if(params.endDate){
            params.endDate = transformDate(params.endDate, transform);
          }


          let queryString = null;
          for(let param in params){
            
            if(param === 'method' || param === 'cmd' || param === 'whome') continue;
            
            if(queryString === null){
              queryString = '?';
            } else {
              queryString += '&';
            }
  
            if(param === 'continuationKey') {
              queryString += `${param}=${params[param]}`
            } else {
              queryString += `${param}=${encodeURIComponent(params[param])}`
            }
  
          }
          params.contract = queryString;

        } else {

          // ALL SKYGSM CALLS NOW REQUIRE A SITEMARID PARAMETER TO BE PASSED
          var sitemarid = false;
          var policyowningmaridIsZero = false;

          var variations = ['PolicyOwningMARId'];
          $.each(variations, function(i, v){

            var pom = params[v];
            if(typeof pom == 'undefined'){
              pom = params[v.toLowerCase()];
            };

            if(typeof pom == 'undefined'){
              pom = params[v.toUpperCase()];
            };

            if(typeof pom == 'undefined'){
              pom = false;
            };

            policyowningmaridIsZero = typeof pom != 'undefined' && (pom === 0 || pom === '0')

          })

          // CHECK IF IT IS ZERO
          if(policyowningmaridIsZero){

            // IF POLICY ID IS PASSED, AND POLICYID IS ZERO
            // THEN SET THE SITEMARID VALUE AS THE PARENT CONSOLE MARID
            // (ZERO IS ALREADY PASSED AS POLICYOWNINGMARID)
            params.sitemarid = psMARID;

          } else {

            $.each(params, function(key, value){
              var matchesmarid = key.toLowerCase().indexOf('marid') > -1;
              if(matchesmarid){
                sitemarid = value;
              };
            });

            if(sitemarid !== false){
              params.sitemarid = sitemarid;
            };

          };

          // CLEAN UP PARAMETERS
          params.contract = JSON.stringify(params);
          delete params.contract.includeContract;
          delete params.contract.sitemarid;
          delete params.contract.whome;
          delete params.includeContract;

        };

      }

    }

    return params;

  },

  testIfBDAPIendpoint(url){
    
    var u = url && url.toLowerCase();
    return (
      u && u.indexOf('/api/commandlog') > -1 ||
      u && u.indexOf('/api/fileexceptions') > -1 ||
      u && u.indexOf('/fileexception/') > -1 ||
      u && u.indexOf('/api/scanhistory') > -1 ||
      u && u.indexOf('/events/urlexception') > -1 ||
      u && u.indexOf('/scriptexception/data') > -1
    );
    
  },

  addBDAPIPaginationParams(url, params, store){
    if (wrjs.Data.testIfBDAPIendpoint(url)) { 
      params.pageNo = params.start || 1;
      params.pageSize = params.limit || store.limit || 50;
    }
    return params;
  },

  updateTotalCount(url, data, store) {
    if (wrjs.Data.testIfBDAPIendpoint(url) && store) {
      var lastOptions = store.lastOptions;
      var pageSize = lastOptions && Number(lastOptions.limit) || store.limit || 50;
      var pageNo = lastOptions && Number(lastOptions.start) || store.start || 1;
      data.totalCount = pageSize * pageNo;
      if (data.continuationKey) {
        data.totalCount = data.totalCount + 1;
      }
      return data;
    }
    return data;
  },

  makeAjaxCall: function(config, store){

    var url = config.url ? config.url : '';

    var params = config.params ? config.params : {};
    $.extend(params, wrjs.Data.defaultParams);

    // NEW SKYGSM SUPPORT - CONVERT ALL PARAMS TO JSON STRINGIFIED STRING, POSTED UNDER "CONTRACT"
    params = wrjs.Data.convertParamsToContract(url, params);
    
    var convert = config.convert || false;

    var success = function(data, textStatus, response){
      wrjs.Data.errorHandler(data, textStatus, this)
    };
    if(config.success){
      success = function(data, textStatus, response){
        if(convert != false){
          var converted = convert(data, store);
          if(typeof converted != 'undefined'){
            data = converted;
          }
        }
        let dataObject = wrjs.Data.updateTotalCount(url, data, store);
        config.success(dataObject, textStatus, response, this)
      }
    }


    var failure = config.failure ? config.failure : function(response, textStatus){
      wrjs.Data.errorHandler(response, textStatus, this)
    };
    var type = config.type ? config.type : 'POST';
    var local = config.local === true || params.local === true;
    var timestamp = typeof config.timestamp != 'undefined' && config.timestamp === false ? '' : '&version=' + Date.now();
    var multiple = params.multiple ? params.multiple : false;

    //console.log('WRJS makeAjaxCall', multiple, local, config, params);

    // GO CHECK IF LOADMASK IS PRESENT ON ANY LINKED VIEWS AND SHOW MASK IF TRUE
    if(store){
      var views = store.getAllViews();
      if(views){
        $.each(views, function(i, v){

          var view = wrjs.View.viewManager[v];
          if(view){

            if(view.loadMask){

              if(view.renderTo){

                setTimeout(function(){
                  var masktarget = $('#' + view.id);
                  var wrapper = masktarget.closest('.wrjs_grid');
                  if(wrapper.length > 0){
                    masktarget = wrapper;
                  }
                  masktarget.mask(window.ls.text_loading, view.loadMask);
                }, 10)

              }

            }

            if(typeof view.setLoading == 'function'){
              view.setLoading();
            }

          }

        })
      }
    }


    // GET CMD VALUE
    var cmd = url.toLowerCase().split('cmd=');
    cmd = cmd.length > 1 ? cmd[1] : false;
    params.cmd = cmd;

    // LOGIC TO ABORT ANY PRE-EXISTNG CALL WITH THE SAME CMD VALUE
    if(cmd && !multiple){
      var priorcall = wrjs.Data.xhrs[cmd];
      if(priorcall && typeof priorcall.abort == 'function'){
        priorcall.abort();
      }
    }

    if(wrjs.local || local){

      return wrjs.Data.getLocalData(url, params, success, failure);

    } else {

      wrjs.Data.ajaxCount++;

      var opts = {
        type: type,
        method: type,
        url: url + timestamp,
        cache: false,
        dataType: 'json',
        data: params
      };

      // CREATE THE AJAX CALL
      var xhr = $.ajax(opts);
      if(xhr){
        if(xhr.done){
          xhr.done(function(data, textStatus, jqXHR){

            //	console.log('DONE', data, textStatus, jqXHR);

            // IF SUCCESSFUL, REMOVE FROM THE CURRENT XHR OBJECT
            if(cmd){
              delete wrjs.Data.xhrs[cmd];
            }
            ;

            // IF 200 AND SUCCESS PARAMS IS RETURNED
            // CALL THE CORRECT SUCCESS / FAILURE FUNCTION BASED ON THE SUCCESS BOOL

            // !!!!!!!!!!
            // THIS CODE HAS TO REMAIN, OTHERWISE WRJS STORES USING THE SUCCESS PARAM WILL ASSUEM STORE LOAD HAS BEEN SUCCESSFUL ON FAILURE
            // !!!!!!!!!!

            if(jqXHR.status == 200 && data && typeof data.success != 'undefined'){

              if(data.success){
                success(data, textStatus, jqXHR, this);
              } else {
                failure(jqXHR, textStatus, this)
              }
              ;

            } else {

              success(data, textStatus, jqXHR, this);

            }
            ;

          });
        }

        if(xhr.fail){
          xhr.fail(function(jqXHR, textStatus, errorThrown){

            //console.log('FAIL', jqXHR.status, jqXHR, textStatus, errorThrown);

            if(jqXHR.status === 200 && !jqXHR.responseText){
              success({}, textStatus, jqXHR, this);
            }
            else {
              failure(jqXHR, textStatus, this);
            }

          });
        }
      }

      // STORE THE XHR OBJECT IN MEMORY FOR ABORTING LATER IF NECESSARY
      if(cmd){
        wrjs.Data.xhrs[cmd] = xhr;
      }

    }
    ;

  }

};


// **********************************************************************************
// STORE MANAGER
// **********************************************************************************

wrjs.Data.storeManager = {

  // CONFIG TO GO HERE

};


// **********************************************************************************
// GLOBAL FUNCTION
// **********************************************************************************

if(typeof wrjs.get == 'undefined'){

  wrjs.get = function(id){

    var el = wrjs.Data.storeManager[id] || wrjs.View.viewManager[id];

    // CHECK IT IS ACTUALLY AN OBJECT, AND NOT AN ARRAY OR STRING
    if(typeof el == 'object'){
      if($.isArray(el)){
        el = false;
      }
    } else {
      el = false;
    }

    return el;

  };

}
;
